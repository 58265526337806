import { useSelector, useDispatch } from "react-redux";
import { setDebugMode } from "../../redux/settings";
import { useEffect, useState, useRef } from "react";
import { List, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";
import { TextField, Alert, IconButton, Button } from "@mui/material";
import { Card, CardHeader, CardContent } from "@mui/material";

import { deleteService } from "../../redux/settings";
import { setLoading } from "../../redux/office";

import { NoOfficesSettings } from "../../components/NoOfficesSettings/NoOfficesSettings";
import { SubGroup } from "../../components/Subgroup/Subgroup";
import LaunchIcon from "@mui/icons-material/Launch";

import CropFreeIcon from "@mui/icons-material/CropFree";
import AddIcon from "@mui/icons-material/Add";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import BugReportIcon from "@mui/icons-material/BugReport";
import InfoIcon from "@mui/icons-material/Info";

import { ModalSettings } from "../../components/ModalSettings/ModalSettings";
import { ConfirmModal } from "../../components/ConfirmModal/ConfirmModal";
import { fetchWithTimeout } from "../../utils/fetchWithTimeout";
import { isIOS } from "react-device-detect";

export const Settings = () => {
  const dispatch = useDispatch();
  const { all_users } = useSelector((state) => state.auth);
  const { all_services, isDebugMode } = useSelector((state) => state.settings);
  const { all_offices, loading } = useSelector((state) => state.office);

  const [officeResp, setOfficeResp] = useState([]);
  const [serviceResp, setServiceResp] = useState([]);

  const [showModal, setShowModal] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [serviceToDelete, setServiceToDelete] = useState(null);

  const getOfficesData = async () => {
    const officeData = (
      await Promise.allSettled(
        all_offices.map(async (office) => {
          const internal = await fetchWithTimeout(`${office?.address[0]}`, 3000)
            .then((resp) => {
              // console.log("resp", resp);
              return !!resp;
            })
            .catch((e) => {
              // console.log("e", office?.address[0], e);
              return false;
            });
          const external = await fetchWithTimeout(`${office?.address[1]}`, 3000)
            .then((resp) => {
              // console.log("resp", resp);
              return !!resp;
            })
            .catch((e) => {
              // console.log("e", office?.address[1], e);
              return false;
            });
          // console.log(`${office?.name_comment}`, { internal: internal, external: external });

          return { ...office, internal: internal, external: external };
        })
      )
    )
      .map((item) => item?.value)
      .map((office) => [
        { ...office, address: [office?.address[0]], success: office?.internal },
        { ...office, address: [office?.address[1]], success: office?.external }
      ])
      .flat();
    // console.log("officeData", officeData);
    return officeData;
  };

  const getServicesData = async () => {
    const responsesServices = (
      await Promise.allSettled(
        all_services.map(
          async (service) =>
            await fetchWithTimeout(`${service?.url}`, 2500)
              .then(() => ({ url: service?.url, success: true }))
              .catch(() => ({ url: service?.url, success: false }))
        )
      )
    ).map((item) => item?.value);
    // console.log("responsesServices", responsesServices);
    return responsesServices;

    // const dataServices= responses.map((item) =>
    //   item?.success ? { url: item?.url, success: true } : item
    // );
  };

  const checkRoutes = async () => {
    try {
      dispatch(setLoading(true));
      const respoffices = await getOfficesData();
      const respServices = await getServicesData();

      // console.log("respoffices", respoffices);

      // console.log("data", data);

      // console.log("data", serviceData);

      setOfficeResp(respoffices);
      // console.log("respoffices", respoffices);
      setServiceResp(respServices);
    } catch (e) {
    } finally {
      // setTimeout(() => {
      //   setLoading(false);
      // }, 1500);
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    // console.log("useEffect");
    checkRoutes();
  }, []);

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleShowDeleteConfirm = (data) => {
    setServiceToDelete(data);
    setShowDeleteConfirm(true);
  };

  const handleDeleteModalCallback = (bool) => {
    if (bool) {
      dispatch(deleteService(serviceToDelete));
      setServiceToDelete(null);
    }
  };

  const handleClickBtn = (url) => {
    window.open(url);
  };

  const deleteModalText = `You are going to delete the service entry ${serviceToDelete?.name}. Are you sure?`;

  const handleOffDebug = () => {
    dispatch(setDebugMode(false));
  };

  const handleOnDebug = () => {
    dispatch(setDebugMode(true));
  };

  const isDebugAvailable = !!all_users?.length ? all_users.some((user) => user?.is_admin || user?.root) : false;

  // const handleHttpData = async () => {
  //   const resp = await fetch("http://178.172.194.139:24517/testimage", { method: "GET" });

  //   console.log("resp");
  // };

  return (
    <>
      {(!!all_offices?.length || !!all_services?.length) && (
        <div className={"fixed_account_btn"} style={{ bottom: "12px" }}>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={handleShowModal}
            sx={{ padding: "8px 16px", borderRadius: "16px 16px 16px 6px" }}
            disabled={loading}
          >
            Add trusted service
          </Button>
        </div>
      )}
      {/* style={{ filter: loading ? "blur(2px)" : "none" }} */}
      <div style={{ marginTop: "8px" }}>
        <SubGroup label={"Application"} />
        <ListItem>
          <ListItemIcon>
            <InfoIcon />
          </ListItemIcon>
          <ListItemText>
            Application version: <span style={{ color: "#1976d2", fontWeight: "600" }}>6.14.9.4</span>{" "}
          </ListItemText>
        </ListItem>
        {/* <ListItem>
          <ListItemIcon>
            <InfoIcon />
          </ListItemIcon>
          <ListItemText>
            <Button variant="outlined" onClick={handleHttpData}>
              get http-data
            </Button>
          </ListItemText>
        </ListItem> */}

        {isDebugAvailable && (
          <ListItem sx={{ alignItems: "center" }}>
            <ListItemIcon>
              <BugReportIcon />
            </ListItemIcon>
            <ListItemText>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ paddingTop: "3px" }}>Debug mode:</div>

                <div style={{ marginLeft: "8px" }}>
                  <Button
                    variant={!isDebugMode ? "contained" : "outlined"}
                    sx={{ borderRadius: "16px 0 0 16px", padding: `${!isDebugMode ? "4px" : "3px"} 4px` }}
                    onClick={handleOffDebug}
                  >
                    Off
                  </Button>
                  <Button
                    variant={isDebugMode ? "contained" : "outlined"}
                    sx={{ borderRadius: "0 16px 16px 0", padding: `${isDebugMode ? "4px" : "3px"} 4px` }}
                    onClick={handleOnDebug}
                  >
                    On
                  </Button>
                </div>
              </div>
            </ListItemText>
          </ListItem>
        )}

        <SubGroup label={"Trusted relays and services"} />
        {(!!all_offices?.length || !!all_services?.length) && (
          <>
            <List sx={{ paddingBottom: "36px" }}>
              {!!all_offices?.length &&
                !!officeResp?.length &&
                officeResp.map((office, idx) => {
                  const isSuccess = !!officeResp[idx]?.success;
                  return (
                    <Alert
                      severity={isSuccess ? "success" : "error"}
                      sx={{ position: "relative", marginBottom: "8px", alignItems: "center" }}
                    >
                      <div>Title: {office?.name_comment || office?.name}</div>
                      <div>Type: Relay</div>
                      <div>Biom name: {office?.biom_name || "Unknown"}</div>
                      <div>Url: {office?.address[0]}</div>
                      <IconButton
                        sx={{ position: "absolute", top: "calc(50% - 20px)", right: "0" }}
                        onClick={() => handleClickBtn(office?.address[0])}
                      >
                        <LaunchIcon />
                      </IconButton>
                    </Alert>
                  );
                })}
              {!!all_services?.length &&
                !!serviceResp?.length &&
                all_services.map((service, idx) => {
                  const isSuccess = !!serviceResp[idx]?.success;
                  // console.log("service", service);
                  return (
                    <Alert
                      severity={isSuccess ? "success" : "error"}
                      sx={{ position: "relative", marginBottom: "8px", alignItems: "center" }}
                    >
                      <div>Title: {service?.name_comment || service?.name}</div>
                      <div>Type: Service</div>
                      <div>Biom name: {service?.biom_name || "Unknown"}</div>
                      <div>Url: {service?.url}</div>
                      <IconButton
                        sx={{ position: "absolute", top: "3px", right: "0" }}
                        onClick={() => handleClickBtn(service?.url)}
                      >
                        <LaunchIcon />
                      </IconButton>
                      <IconButton
                        sx={{ position: "absolute", bottom: "3px", right: "0" }}
                        onClick={() => handleShowDeleteConfirm(service)}
                      >
                        <DeleteForeverIcon color="error" />
                      </IconButton>
                    </Alert>
                  );
                })}
              {/* <div style={{ height: "500px" }}></div> */}
            </List>
          </>
        )}
        {!all_offices?.length && !all_services?.length && <NoOfficesSettings handleShowModal={handleShowModal} />}
        <ConfirmModal
          open={showDeleteConfirm}
          setOpen={setShowDeleteConfirm}
          callback={handleDeleteModalCallback}
          isDelete={true}
          text={deleteModalText}
        />
        <ModalSettings showModal={showModal} setShowModal={setShowModal} />
      </div>
    </>
  );
};
