export const default_office_config = [
  "address",
  "description",
  "name",
  "pins",
  "standalone",
  "biom_uuid",
  "service_uuid",
  "certificate"
];
