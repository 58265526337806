import { useEffect, useState } from "react";

import { Card, CardContent, CardMedia, CardActionArea } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Typography } from "@mui/material";

import { setIsTrustedUrlError } from "../../redux/notify";
import { useDispatch, useSelector } from "react-redux";
// import { openPin } from "../../API/OpenPin";
import { openPin } from "../../redux/office";

import { openNotify } from "../../components/OpenNotify/openNotify";
import { checkConnection } from "../../Axios/checkConnection";

import { setIsConnection } from "../../redux/notify";

import { handleToken } from "../../utils/handleToken";
import { checkFastestUrl } from "../../utils/checkFastestUrl";

export const PinComponent = (props) => {
  const dispatch = useDispatch();
  const {
    pinConfig,
    description,
    number,
    interrupt,
    pinCount,
    important,
    handleOpenModal,
    modalResult,
    setModalResult,
    isBlockDisabled
  } = props;

  const { all_users } = useSelector((state) => state.auth);
  const { selected_office_info } = useSelector((state) => state.office);
  const { isConnection } = useSelector((state) => state.notify);

  const user_uuid = selected_office_info?.default_account;
  const default_user = all_users.find((account) => account?.user_uuid === user_uuid);
  const service_uuid = selected_office_info?.service_uuid;

  const [open, setOpen] = useState(false);

  const [loading, setLoading] = useState(false);

  const isButtonType = pinConfig?.type === "button";

  const checkClickAccess = async () => {
    if (!!important) {
      const modalText = (
        <>
          You're going to <strong style={{ color: "#ed6c02" }}>{!open ? "open" : "close"}</strong> the{" "}
          <strong style={{ color: "#ed6c02" }}>{description}</strong>. Are you sure?
        </>
      );

      handleOpenModal(number, modalText);
    } else {
      handleCardClick(true);
    }
  };

  const handleCardClick = async (bool) => {
    // console.log("handleCardClick", bool);
    if (!!pinConfig?.access && (!important || bool) && !isBlockDisabled) {
      setLoading(true);

      try {
        const fastestUrl = await checkFastestUrl(selected_office_info?.address[0], selected_office_info?.address[1]);
        if (!fastestUrl) {
          dispatch(setIsTrustedUrlError([selected_office_info?.address[0], selected_office_info?.address[1]]));
          return null;
        }
        // const url_external = selected_office_info?.address[0];
        // const url_internal = selected_office_info?.address[1];
        // const current_url = check ? url_external : url_internal;
        const req_url = `${fastestUrl}/send_signal/${number}`;
        const token = await handleToken(default_user, selected_office_info);
        const pub_ssl = selected_office_info?.certificate;
        const data = {
          url: req_url,
          token: token,
          pub_ssl: pub_ssl
        };
        try {
          await dispatch(openPin(data)).then(() => (isButtonType ? setOpen(true) : setOpen((prev) => !prev)));
          if (!isConnection) {
            dispatch(setIsConnection(true));
          }
        } catch (error) {
          const error_code = error?.response?.status;
          const message = `Error ${error?.response?.status}: ${
            error?.response?.data?.message || error?.response?.data?.error_message || ""
          }`;
          // console.log("error", error);
          switch (error_code) {
            case 401: {
              await dispatch(openPin(data)).then((resp) => console.log("after error", resp));
              break;
            }
            case 403: {
              console.log("error", error);
              openNotify("error", `${message} you don't have permission for this action`);
              break;
            }
            default: {
              console.log("error default", error);
              const check = await checkConnection();
              if (!!check) {
                await dispatch(openPin(data)).then(() => (isButtonType ? setOpen(true) : setOpen((prev) => !prev)));
                // openNotify("error", message);
              }
            }
          }
        }
      } catch (e) {
        console.log("e", e);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (open && isButtonType) {
      setTimeout(() => {
        setOpen(false);
      }, interrupt * 1000);
    }
  }, [open]);

  useEffect(() => {
    if (!pinConfig?.value) {
      // if door is open
      if (isButtonType) {
        // console.log("if door is open");
        setOpen(true);
        setTimeout(() => {
          setOpen(false);
        }, interrupt * 1000);
      }
    }
    setOpen(!pinConfig?.value);
  }, [pinConfig]);

  useEffect(() => {
    // console.log("modalResult", modalResult);
    if (modalResult === number) {
      handleCardClick(true);
      setModalResult(null);
    }
  }, [modalResult]);

  return (
    <LoadingButton
      onClick={checkClickAccess}
      disabled={isBlockDisabled || !pinConfig?.access}
      loading={loading}
      variant="contained"
      color={open ? "success" : "primary"}
      sx={{
        height: `calc(${100 / pinCount}% - 16px)`,
        minHeight: "76px",
        position: "relative",
        display: "flex",
        alignItems: "center",
        width: "100%",
        marginBottom: "8px",
        // border: "1px solid #1976d2",

        background: loading ? "blue" : !pinConfig?.access ? "#bdbdbd" : open ? "#2e7d32" : "#1976d2",
        width: "200px",
        borderRadius: "16px"
      }}
    >
      <div>
        <Typography variant="subtitle1" sx={{ color: "white" }}>
          {description}
        </Typography>
        <Typography variant="subtitle2" sx={{ color: "white" }}>
          {open ? "Open" : "Closed"}
        </Typography>
      </div>
    </LoadingButton>
  );
};
