import { store } from "../redux/store";
import { setLoading } from "../redux/office";
import { checkUrlWithTimeout } from "./checkUrlWithTimeout";
import { fetchWithTimeout } from "./fetchWithTimeout";

export const checkFastestUrl = async (url0, url1) => {
  const dispatch = store.dispatch;
  try {
    dispatch(setLoading(true));
    // console.log("handleCheckUrls");
    const external_url = url0;
    const internal_url = url1;

    const urlArr = [external_url, internal_url];
    // console.log("urlArr", urlArr);

    const respAny = await Promise.any([fetchWithTimeout(external_url, 5000), fetchWithTimeout(internal_url, 5000)]);

    // console.log("respAny", respAny);
    // const resp = (await Promise.allSettled(urlArr.map(async (url) => ({ success: await checkUrl(url), url: url })))).map(
    //   (item) => item?.value
    // );

    // const availableUrl = respRace.find((item) => item?.success)?.url;
    // console.log("availableUrl", availableUrl);

    return respAny?.url;
  } catch (e) {
    // console.log(`e`, e);
    return null;
  } finally {
    dispatch(setLoading(false));
  }
};
