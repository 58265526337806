import { createCookieName } from "./createCookieName";

function getCookie(name) {
  // console.log("name", name);
  var matches = document.cookie.match(
    new RegExp("(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, "\\$1") + "=([^;]*)")
  );
  return matches ? decodeURIComponent(matches[1]) : null;
}

export const checkToken = (user_uuid, service_uuid) => {
  const cookieName = createCookieName(user_uuid, service_uuid);
  // console.log("cookieName", cookieName);
  // console.log("getCookie", getCookie(cookieName));
  return getCookie(cookieName);
};
