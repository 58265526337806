// ---------- react, lib ----------
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { setPasswordErrorData } from "../../redux/notify";
import { setDefaultAccount, addNewOffice } from "../../redux/office";
import { useNavigate } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

// ---------- components ----------
import { ModalOffices, SELECT_OFFICE } from "../ModalOffices/ModalOffices";
import FullScreenDialog from "../FullScreenDialog/FullScreenDialog";
import { CloseButton } from "../CloseButton/CloseButton";
import { ScannerComponent } from "../Scaner/ScannerComponent";
import { FormTextField } from "../FormTextField/FormTextField";
import { FormTextFieldUrl } from "../FormTextField/FormTextFieldUrl";
import { ListItem, Button, Divider, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { openNotify } from "../OpenNotify/openNotify";

// ---------- API ------------------
import { handleClassicReg } from "./handleClassicReg";
import { handleClassicSignIn } from "./handleClassicSignIn";
import { fetchWithTimeout } from "../../utils/fetchWithTimeout";

// ---------- encryption -----------
import { appRoutes } from "../../utils/constants";
import { saveToken } from "../../utils/saveToken";
import { hashMd5 } from "../../utils/hashMd5";
import { scannerType } from "../../utils/constants";
import { setRegData } from "../../redux/scaner";

export const CLASSIC_SIGN_UP = "CLASSIC_SIGN_UP";
export const CLASSIC_SIGN_IN = "CLASSIC_SIGN_IN";

export const ClassicRegForm = ({ callbackClose, type }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { all_users } = useSelector((state) => state.auth);
  const { all_offices } = useSelector((state) => state.office);
  const { reg_data } = useSelector((state) => state.scaner);

  const [openScan, setOpenScan] = useState(false);

  const [showPass, setShowPass] = useState(false);
  const [showPassRepeat, setShowPassRepeat] = useState(false);

  const [showOfficeModal, setShowOfficeModal] = useState(false);

  const isSignUp = type === CLASSIC_SIGN_UP;

  const defaultValues = isSignUp
    ? {
        service_url: "",
        email: "",
        password: "",
        repeat_password: "",
        first_name: "",
        last_name: ""
      }
    : {
        service_url: "",
        email: "",
        password: ""
      };

  const testMailReg = /\S+@\S+\.\S+/;

  const checkUrl = async (url) =>
    await fetchWithTimeout(`${url}`, { method: "HEAD" })
      .then(async (resp) => {
        return true;
      })
      .catch((e) => {
        const check = e?.message === "Failed to fetch";
        return !check;
      });

  const userSchema = isSignUp
    ? Yup.object().shape({
        service_url: Yup.string()
          .test("check_url", "Check the url", async (val) => {
            return await checkUrl(val);
          })
          .required("Required field"),
        email: Yup.string()
          .test("is_valid", "Incorrect email", (val) => testMailReg.test(val))
          .required("Required field"),
        password: Yup.string()
          .test("is_min", "At least 4 characters", (val, values) => val?.length >= 4)
          .required("Required field"),
        repeat_password: Yup.string()
          .test("is_pass_equal", "Passwords don't match", (val, values) => val === values?.parent?.password)
          .required("Required field"),
        first_name: Yup.string().required("Required field"),
        last_name: Yup.string().required("Required field")
      })
    : Yup.object().shape({
        service_url: Yup.string()
          .test("check_url", "Check the url", async (val) => {
            return await checkUrl(val);
          })
          .required("Required field"),
        email: Yup.string()
          .test("is_valid", "Incorrect email", (val) => testMailReg.test(val))
          .required("Required field"),
        password: Yup.string()
          .test("is_min", "At least 4 characters", (val, values) => val?.length >= 4)
          .required("Required field")
      });

  const methods = useForm({
    resolver: yupResolver(userSchema),
    reValidateMode: "onBlur",
    mode: "onBlur",
    shouldFocusError: true,
    defaultValues: {
      ...defaultValues
    }
  });

  const {
    register,
    reset,
    setError,
    resetField,
    getValues,
    watch,
    setValue,
    handleSubmit,
    formState: { errors, isSubmitting }
  } = methods;

  const checkServiceDefAcc = (user_uuid) => {
    const service_uuid = reg_data?.service_uuid;
    const check = !!all_offices.find((office) => office?.service_uuid === service_uuid)?.default_account;
    if (!check) {
      const data = { user_uuid: user_uuid, service_uuid: service_uuid };
      dispatch(setDefaultAccount(data));
    }
  };

  const addNewOfficeConfig = (data, newCurrentUserData) => {
    // console.log("data", data);
    // console.log("newCurrentUserData", newCurrentUserData);
    const checkIsUniq = !all_offices.some((office) => office?.service_uuid === data?.service_uuid);
    // console.log("checkIsUniq", checkIsUniq);
    if (checkIsUniq) {
      const default_user_uuid = !!all_users?.length
        ? all_users.find((user) => (user?.biom?.uuid || user?.biom?.biom_uuid) === data?.biom_uuid)?.user_uuid
        : newCurrentUserData?.user_uuid;
      const officeConfig = { ...data, default_account: default_user_uuid };
      console.log("officeConfig", officeConfig);
      dispatch(addNewOffice(officeConfig));
      dispatch(setRegData(null));
    }
  };

  const onSubmit = async (values) => {
    try {
      // console.log("values", values);
      const url = values?.service_url;
      const email = values?.email;
      const md5password = hashMd5(values?.password);

      const newCurrentUserData = isSignUp
        ? await handleClassicReg(values)
        : await handleClassicSignIn(url, email, md5password);

      if (!!newCurrentUserData) {
        checkServiceDefAcc(newCurrentUserData?.user_uuid);
        navigate(appRoutes.accounts.path);
        callbackClose(false);
        openNotify(
          "success",
          `Account ${CLASSIC_SIGN_UP ? "registration" : "recovery"} ${
            (newCurrentUserData?.first_name, newCurrentUserData?.last_name)
          } has been successfully completed`
        );
        dispatch(setPasswordErrorData(null));
        // console.log("reg_data", reg_data);
        if (!!Object.keys(reg_data)?.length) {
          addNewOfficeConfig(reg_data, newCurrentUserData);
        }
      } else {
        openNotify("error", `Something went wrong!`);
      }
    } catch (e) {
      console.log("e", e);
    }
  };

  const fieldLabel = (fieldName) => {
    switch (fieldName) {
      case "service_url":
        return "Service url *";
      case "first_name":
        return "First name *";
      case "last_name":
        return "Last name *";
      case "email":
        return "Email *";
      case "password":
        return "Password *";
      case "repeat_password":
        return "Confirm password *";
    }
  };

  const handleOpenScaner = () => {
    setOpenScan(true);
  };

  const handleSelectOffice = (office) => {
    if (!!office?.address[0]) {
      setValue("service_url", office?.address[0], { shouldDirty: true, shouldValidate: true });
    }
  };

  const handleShowOfficesModal = () => {
    setShowOfficeModal(true);
  };

  useEffect(() => {
    // console.log("reg_data", reg_data);
    if (!!reg_data?.address[0]) {
      setValue("service_url", reg_data?.address[0], { shouldDirty: true, shouldValidate: true });
    }
  }, [reg_data?.address[0]]);

  const checkUrlField = async () => {
    const service_url = watch("service_url");
    // console.log("service_url", service_url);
    if (!!service_url) {
      const check = await checkUrl(service_url);
      // alert(`checkUrlField ${service_url} ${check}`);
      // console.log("check", check);
      resetField("service_url", { keepDirty: true, keepError: !check, defaultValue: service_url });
    }
  };

  window.onfocus = async function checkOnFocus() {
    // console.log("focus");
    checkUrlField();
  };

  useEffect(
    () => () => {
      reset(defaultValues);
    },
    []
  );

  // window.onblur = function () {
  //   console.log("blur");
  // };

  return (
    <>
      <div style={{ marginTop: "8px" }}>
        <Typography
          color="primary"
          sx={{
            padding: "0 16px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            fontWeight: "600",
            fontSize: "20px"
          }}
        >
          {isSignUp ? "Creating a classic account" : "Adding an existing account"}
          <CloseButton handleCallback={callbackClose} />
        </Typography>

        <Divider sx={{ marginBottom: "16px" }} />
        {/* <Typography color="primary" sx={{ paddingLeft: "16px", margin: "16px 0", fontSize: "16px" }}>
          Enter the data in the form fields
        </Typography> */}
      </div>
      <FormProvider {...methods}>
        {Object.keys(defaultValues).map((item, idx) => {
          const checkIsPassword = item === "password";
          const checkIsPasswordRepeat = item === "repeat_password";
          const isPass = checkIsPassword || checkIsPasswordRepeat;
          return (
            <>
              {item === "first_name" && (
                <div style={{ marginTop: "16px", paddingLeft: "16px" }}>
                  <Typography color="primary" sx={{ fontWeight: "600", fontSize: "20px" }}>
                    Tell us about yourself
                  </Typography>
                  <Typography color="primary" sx={{ marginBottom: "16px", fontSize: "16px" }}>
                    This will help personalize your account
                  </Typography>
                </div>
              )}

              <div
                style={{ padding: `4px 16px ${Object.keys(defaultValues).length === idx + 1 ? "36px" : "24px"} 16px` }}
                key={`${item}_classic_reg_form`}
              >
                {item === "service_url" && (
                  <FormTextFieldUrl
                    fieldLabel={fieldLabel(item)}
                    fieldName={item}
                    isDisabled={isSubmitting}
                    isAnyOffices={!!all_offices?.length}
                    handleOpenScaner={handleOpenScaner}
                    handleShowOfficesModal={handleShowOfficesModal}
                  />
                  // <div>
                  //   <Button fullWidth variant="outlined">
                  //     Specify the url of the service
                  //   </Button>
                  // </div>
                )}
                {item !== "service_url" && (
                  <FormTextField
                    type={item === "email" ? "email" : "text"}
                    fieldLabel={fieldLabel(item)}
                    fieldName={item}
                    maxLength={isPass ? 36 : 64}
                    isVisible={
                      isPass ? (checkIsPassword && showPass) || (checkIsPasswordRepeat && showPassRepeat) : true
                    }
                    callbackVisibility={
                      (checkIsPassword && setShowPass) || (checkIsPasswordRepeat && setShowPassRepeat) || null
                    }
                    isDisabled={isSubmitting}
                    key={`${item}_reg`}
                  />
                )}
              </div>
            </>
          );
        })}
      </FormProvider>
      <ModalOffices
        type={SELECT_OFFICE}
        showModal={showOfficeModal}
        setShowModal={setShowOfficeModal}
        handleSelectOffice={handleSelectOffice}
        current_url={watch("service_url")}
      />
      <FullScreenDialog
        children={<ScannerComponent type={scannerType.office_classic_reg} callbackClose={setOpenScan} />}
        open={openScan}
        setOpen={setOpenScan}
      />

      <ListItem sx={{ marginBottom: "16px" }}>
        <LoadingButton
          loading={isSubmitting}
          fullWidth
          variant="contained"
          onClick={handleSubmit(onSubmit)}
          sx={{ borderRadius: "12px" }}
        >
          {isSignUp ? "Create an account" : "Add an account"}
        </LoadingButton>
      </ListItem>
    </>
  );
};
