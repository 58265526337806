// ---- react, lib -------------
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Routes, Route, useLocation, useNavigate, Navigate } from "react-router-dom";
import { useMediaQuery } from "@mui/material";

// ---- redux -----------------
import { setIsOpen } from "./redux/backHandler";

// ---- pages -----------------
import { StartPage } from "./Pages/StartPage/StartPage";
import { Account } from "./Pages/Account/Account";
import { AccountsPage } from "./Pages/AccountsPage/AccountsPage";

import { DoorsPage } from "./Pages/DoorsPage/DoorsPage";
import { Offices } from "./Pages/Offices/Offices";
import { InstallPage } from "./Pages/Install/InstallPage";

// ---- icons -----------------
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CloseIcon from "@mui/icons-material/Close";

// ---- components -----------
import { UpdateNotify } from "./components/UpdateNotify/UpdateNotify";
import { DebugComponent } from "./components/DebugComponent/DebugComponent";
import { Settings } from "./Pages/Settings/Settings";
import { ModalPasswordError } from "./components/ModalPasswordError/ModalPasswordError";
import { ModalTrustedUrl } from "./components/ModalTrustedUrl/ModalTrustedUrl";
import { Header } from "./components/Header/Header";
import { Container } from "@mui/material";
import { Notistack } from "./components/Notistack/Notistack";
import { PageWrapper } from "./components/PageWrapper/PageWrapper";
import { FooterNavigation } from "./components/FooterNavigation/FooterNavigation";
import { Alert, CircularProgress } from "@mui/material";

import { animated, useTransition } from "react-spring";
import { appRoutes } from "./utils/constants";
import { useAddToHomeScreenPrompt } from "./hooks/useAddToHomescreenPrompt";
import { PLATFORM, INSTALLED } from "./utils/detectPWA";
import { setCurrentOfficeByUUID } from "./redux/office";

export default function App() {
  // const PWADisplayMode = getPWADisplayMode();
  const [promptable, promptToInstall, isInstalled] = useAddToHomeScreenPrompt();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [initialFetching, setInitialFetching] = useState(true);
  // const vConsole = new VConsole();
  // const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  // useEffect(() => {
  //   alert(`prefersDarkMode = ${prefersDarkMode ? "dark" : "light"}`);
  // }, [prefersDarkMode]);

  const { current_office } = useSelector((state) => state.office);
  const { isConnection } = useSelector((state) => state.notify);

  const initialFunction = async () => {
    try {
      // console.log("initialFunction");
      const prevPath = localStorage.getItem("currentRoute");
      const default_office = !!localStorage.getItem("current_office")
        ? JSON.parse(localStorage.getItem("current_office"))
        : {};
      if (!!default_office?.service_uuid) {
        dispatch(setCurrentOfficeByUUID(default_office?.service_uuid));
      }

      const checkDefaultOffice = !!default_office?.service_uuid && !!default_office?.default_account;
      // console.log("prevPath", prevPath);
      if (checkDefaultOffice) {
        // console.log("office");
        navigate(appRoutes.doors.path);
        return;
      } else {
        if (location.pathname === "/install") {
          // console.log('location.pathname === "/install"');
          navigate("/install");
          return;
        }
        if (location.pathname === "/") {
          // console.log('location.pathname === "/"');
          navigate(prevPath || appRoutes.accounts.path);
          return;
        }
        navigate(prevPath || appRoutes.accounts.path);
      }
    } catch (e) {
    } finally {
      setTimeout(() => {
        setInitialFetching(false);
      }, 270);
    }
  };

  useEffect(() => {
    setInitialFetching(true);
    setTimeout(() => {
      initialFunction();
    }, 200);
  }, []);

  // useEffect(() => {
  //   window.addEventListener("storage", handleStorageChange);
  //   return () => {
  //     window.removeEventListener("storage", handleStorageChange);
  //   };
  // }, []);

  // const handleStorageChange = () => {
  //   setIsOpen(localStorage.getItem("available_update"));
  // };

  // useEffect(() => {
  //   console.log("PWADisplayMode", PWADisplayMode);
  //   alert(PWADisplayMode);
  // }, [PWADisplayMode]);

  const transitions = useTransition(location, {
    keys: (location) => location.pathname,
    from: {
      opacity: 0,
      position: "absolute",
      width: "100%",
      transform: `translate3d(${location.pathname === appRoutes.relays.path ? "-100%" : "100%"}, 0, 0)`
    },
    enter: { opacity: 1, transform: "translate3d(0, 0, 0)" },
    leave: { opacity: 0, transform: `translate3d(-100%, 0, 0)` }
  });

  // useEffect(() => {
  //   console.log(window.navigator.userAgent);
  // }, []);

  const checkPath = location.pathname !== "/install";

  return (
    <Container maxWidth="sm" disableGutters>
      <div
        style={{
          position: "absolute",
          top: "0",
          bottom: "-100px",
          left: "0",
          right: "0",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",

          zIndex: initialFetching ? "20000" : "-2",
          background: "white"
        }}
      >
        {initialFetching && <CircularProgress />}
      </div>
      {checkPath && <Header />}
      {!isConnection && (
        <Alert
          severity="warning"
          sx={{
            width: "100%",
            position: "fixed",
            bottom: "52px",
            left: "0",
            zIndex: "10"
          }}
        >
          No internet connection! 123456
        </Alert>
      )}

      {transitions((styles, item) => (
        <animated.div style={styles}>
          <Routes location={item}>
            {/* <Route path="/" element={<StartPage />} /> */}
            {/* <Route path="/" element={<Navigate to={appRoutes.accounts.path} />} /> */}
            <Route path={appRoutes.accounts.path} element={<PageWrapper page={<AccountsPage />} />} />
            <Route
              path="/install"
              element={
                <InstallPage promptable={promptable} promptToInstall={promptToInstall} isInstalled={isInstalled} />
              }
            />

            <Route path={appRoutes.relays.path} element={<PageWrapper page={<Offices />} />} />
            <Route path={appRoutes.doors.path} element={<PageWrapper page={<DoorsPage />} />} />
            <Route path={appRoutes.accounts.path} element={<PageWrapper page={<AccountsPage />} />} />
            <Route path={appRoutes.account.path} element={<PageWrapper page={<Account />} />} />
            <Route path={appRoutes.settings.path} element={<PageWrapper page={<Settings />} />} />
          </Routes>
        </animated.div>
      ))}

      {checkPath && !initialFetching && <FooterNavigation />}
      <ModalTrustedUrl />
      <DebugComponent />
      <Notistack />
      <UpdateNotify />
    </Container>
  );
}
