// ---------- react, lib ----------
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

// ---------- components ----------
import { FormTextField } from "../FormTextField/FormTextField";
import { TextField, ListItem, Button, FormLabel, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { openNotify } from "../OpenNotify/openNotify";

// ---------- API ------------------

import { createNewUser, signIn, setCurrentUser, addNewUser } from "../../redux/auth";
import { aboutInfoRequest } from "../../redux/auth";
// ---------- encryption -----------
import { keyGen } from "../../utils/keyGen";

import { appRoutes } from "../../utils/constants";
import { saveToken } from "../../utils/saveToken";

export const RegForm = ({ callbackClose }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { reg_data } = useSelector((state) => state.scaner);

  const [keyGenData, setKeyGenData] = useState(null);

  useEffect(() => {
    if (reg_data?.salt) {
      getKeys(reg_data?.salt);
    }
  }, [!!reg_data]);

  const defaultValues = {
    first_name: "",
    last_name: "",
    email: ""
  };

  const getKeys = async (salt) => {
    const resp = await keyGen(salt);
    setKeyGenData(resp);
  };

  const testMailReg = /\S+@\S+\.\S+/;

  const NewUserSchema = Yup.object().shape({
    first_name: Yup.string().required("Required field"),
    last_name: Yup.string().required("Required field"),
    email: Yup.string()
      .test("is_valid", "Incorrect email", (val) => testMailReg.test(val))
      .required("Required field")
  });

  const methods = useForm({
    resolver: yupResolver(NewUserSchema),
    reValidateMode: "onChange",
    mode: "onChange",
    shouldFocusError: true,
    defaultValues: {
      ...defaultValues
    }
  });

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors, isSubmitting }
  } = methods;

  const signInFunc = async (user_uuid) => {
    const req_body = {
      signed_salt: keyGenData.signature,
      qr_token: reg_data?.qr_token,
      uuid: user_uuid,
      depended_map: {}
    };
    const data = {
      url: reg_data?.authentication_url,
      req_body: req_body
    };
    return await dispatch(signIn(data)).unwrap();
  };

  const onSubmit = async (values) => {
    // console.log("values", values);
    // console.log("reg_data", reg_data);
    try {
      const req_body = {
        qr_token: reg_data?.qr_token,
        signed_salt: keyGenData.signature,
        pub_key: keyGenData.pub,
        uinfo: {
          first_name: values.first_name,
          last_name: values.last_name,
          email: values.email
        }
      };
      const newUSerData = {
        url: reg_data?.registration_url,
        req_body: req_body
      };
      const resp_actor = await dispatch(createNewUser(newUSerData)).unwrap();
      const about_data = await dispatch(aboutInfoRequest({ url: reg_data?.about_url })).unwrap();
      const resp_signIn = await signInFunc(resp_actor?.uuid);

      const uinfo = resp_actor?.uinfo;
      const newCurrentUserData = {
        user_uuid: resp_actor?.uuid,
        user_priv_key: keyGenData.priv,
        user_pub_key: keyGenData.pub,
        actor_type: resp_actor.actor_type,
        created: resp_actor?.created,
        root: false,
        email: uinfo?.email,
        first_name: uinfo?.first_name,
        last_name: uinfo?.last_name,
        groups: Array.isArray(uinfo?.groups) ? uinfo?.groups : [],
        biom: {
          biom_uuid: about_data?.biom_uuid,
          biom_domain: about_data?.biom_domain,
          biom_name: about_data?.biom_name
        }
        // services_uuid: [about_data?.service_uuid]
      };
      saveToken(resp_signIn?.session_token, resp_signIn?.expiration, resp_actor?.uuid, about_data?.service_uuid);
      dispatch(setCurrentUser(newCurrentUserData));
      dispatch(addNewUser(newCurrentUserData));
      navigate(appRoutes.accounts.path);
      callbackClose(false);
      openNotify(
        "success",
        `Account registration ${(uinfo?.first_name, uinfo?.last_name)} has been successfully completed`
      );
    } catch (e) {
      console.log("e");
    }
  };

  const fieldLabel = (fieldName) => {
    switch (fieldName) {
      case "first_name":
        return "First name *";
      case "last_name":
        return "Last name *";
      case "email":
        return "Email *";
    }
  };

  return (
    <>
      <div style={{ marginTop: "16px", paddingLeft: "16px" }}>
        <Typography color="primary" sx={{ fontWeight: "600", fontSize: "20px" }}>
          Tell us about yourself
        </Typography>
        <Typography color="primary" sx={{ marginBottom: "16px", fontSize: "16px" }}>
          This will help personalize your account
        </Typography>
      </div>
      <FormProvider {...methods}>
        {Object.keys(defaultValues).map((item, idx) => (
          <div style={{ padding: `4px 16px ${Object.keys(defaultValues).length === idx + 1 ? "36px" : "24px"} 16px` }}>
            <FormTextField
              type={item === "email" ? "email" : "text"}
              fieldLabel={fieldLabel(item)}
              fieldName={item}
              maxLength={28}
              isVisible={true}
              callbackVisibility={null}
              isDisabled={isSubmitting}
              key={`${item}_reg`}
            />
          </div>
        ))}
      </FormProvider>

      <ListItem>
        <LoadingButton
          loading={isSubmitting}
          fullWidth
          variant="outlined"
          onClick={handleSubmit(onSubmit)}
          sx={{ borderRadius: "12px" }}
        >
          Create an account
        </LoadingButton>
      </ListItem>
    </>
  );
};
