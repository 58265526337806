import { fetchWithTimeout } from "./fetchWithTimeout";

export const checkUrlWithTimeout = async (url, timeout) => {
  return await fetchWithTimeout(`${url}`, timeout || 3000)
    .then(async (resp) => {
      // console.log(`resp ${url}`, resp);
      return true;
    })
    .catch((e) => {
      // console.log(`e ${url}`, e);
      const check = e?.message === "Failed to fetch";
      return !check;
    });
};
