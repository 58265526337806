import { useDispatch, useSelector } from "react-redux";

import { DialogWindow } from "../DialogWindow/DialogWindow";
import CloseIcon from "@mui/icons-material/Close";

import { Alert, IconButton, Typography, Divider, Button } from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { setIsTrustedUrlError } from "../../redux/notify";

const style = {
  position: "absolute",
  top: "32px",
  left: "32px",
  width: "calc(100vw - 64px)",
  maxHeight: "calc(100vh - 64px)",
  bgcolor: "background.paper",
  // border: "2px solid #000",
  borderRadius: "16px",
  boxShadow: 24,
  p: 0
};

export const ModalTrustedUrl = () => {
  const dispatch = useDispatch();
  const { isTrustedurlError } = useSelector((state) => state.notify);

  const handleCloseDialog = () => {
    dispatch(setIsTrustedUrlError(null));
  };

  const content = (
    <>
      <div
        style={{ padding: "8px 16px 0 16px", display: "flex", justifyContent: "space-between", alignItems: "center" }}
      >
        <Typography variant="subtitle1">Connection error</Typography>

        <IconButton onClick={handleCloseDialog}>
          <CloseIcon />
        </IconButton>
      </div>
      <Divider />
      <Alert severity="info" sx={{ alignItems: "center" }} icon={false}>
        <p>The requested service is not responding.</p>
        Please check if the service has been added <strong>to the trusted group</strong>. <br /> To add to a trusted
        group, follow these steps:
        <li>follow the link,</li>
        <li>
          click the <strong>"Advanced"</strong> button,
        </li>
        <li>
          click the <strong>"Go to the website"</strong> button,{" "}
        </li>
        <li>After displaying the service content, repeat the request.</li>
        {Array.isArray(isTrustedurlError) &&
          !!isTrustedurlError?.length &&
          isTrustedurlError.map((url) => (
            <Typography sx={{ marginTop: "16px", alignItems: "center" }} key={`${url}_trusted`}>
              Service url: <br />
              <a target="_blank" href={url} style={{ display: "flex", alignItems: "flex-end", fontSize: "14px" }}>
                {url}
                <OpenInNewIcon sx={{ marginLeft: "4px" }} />
              </a>
            </Typography>
          ))}
      </Alert>
      <Alert severity="success" sx={{ alignItems: "center" }}>
        {/* Nothing threatens your data, the Ecosystem54Accesses application uses its own request encryption system instead
        of SSL. */}
        Nothing threatens your data, the above address leads to a secure service that is part of the Ecosystem54-biome.
      </Alert>
      <div style={{ padding: "16px" }}>
        <Button fullWidth variant="contained" onClick={handleCloseDialog}>
          Continue
        </Button>
      </div>
    </>
  );
  return <DialogWindow children={content} open={!!isTrustedurlError} setOpen={handleCloseDialog} styleProps={style} />;
};
