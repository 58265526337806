import { ListItem, ListItemAvatar, Avatar } from "@mui/material";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import PublicIcon from "@mui/icons-material/Public";

export const OfficeAccountItem = ({ user }) => {
  return (
    <ListItem sx={{ padding: "4px 8px" }}>
      <ListItemAvatar>
        <Avatar sx={{ background: "#1976d2" }}>
          {user?.first_name[0]}
          {user?.last_name[0]}
        </Avatar>
      </ListItemAvatar>
      <div>
        <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
          {/* <AlternateEmailIcon color="neutral" sx={{ marginRight: "6px" }} /> */}
          {user?.email}
        </div>
        {/* <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
          <PublicIcon color="neutral" sx={{ marginRight: "6px" }} />
          {user?.biom?.biom_name || user?.biom?.name}
        </div> */}
      </div>
    </ListItem>
  );
};
