import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export function ConfirmModal({ open, setOpen, callback, text, isDelete, pinNumber }) {
  const handleCancel = () => {
    // console.log("handleCancel");
    setOpen(false);
    callback(false, pinNumber);
  };

  const handleConfirm = () => {
    // console.log("handleConfirm");
    setOpen(false);
    callback(true, pinNumber);
  };

  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{ style: { borderRadius: "16px" } }}
    >
      <DialogTitle id="alert-dialog-title">Confirm the action</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{text}</DialogContentText>
      </DialogContent>
      <div style={{ padding: "0px 16px 16px 16px", display: "flex" }}>
        <div style={{ width: "50%", padding: "0 8px 0 0", marginRight: "32px" }}>
          <Button onClick={handleCancel} fullWidth variant="contained" color="primary" sx={{ height: "64px" }}>
            Cancel
          </Button>
        </div>
        <div style={{ width: "50%", padding: "0 0 0 0" }}>
          <Button
            onClick={handleConfirm}
            fullWidth
            variant="contained"
            color={isDelete ? "error" : "success"}
            sx={{ height: "64px" }}
          >
            OK
          </Button>
        </div>
      </div>
    </Dialog>
  );
}
