import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";

import { createAxios } from "../Axios/API_AXIOS";

export const createNewUser = createAsyncThunk("auth/createNewUser", async (props, { rejectWithValue }) => {
  // console.log("adminLogin", data);
  // console.log("createNewUser", props);
  const { url, req_body } = props;
  try {
    const response = await createAxios().post(url, req_body);
    return response?.data?.user;
  } catch (error) {
    return rejectWithValue(error?.response?.data);
  }
});

export const signIn = createAsyncThunk("auth/signIn", async (props, { rejectWithValue }) => {
  // console.log("adminLogin", data);
  const { url, req_body } = props;
  try {
    const response = await createAxios().post(url, req_body);
    return response?.data;
  } catch (error) {
    return rejectWithValue(error?.response?.data);
  }
});

export const createNewSalt = createAsyncThunk("auth/createNewSalt", async (props, { rejectWithValue }) => {
  // console.log("adminLogin", data);
  const { url, req_body } = props;
  // console.log("createNewSalt", url, req_body);
  try {
    const response = await createAxios().post(url, req_body);
    return response?.data;
  } catch (error) {
    return rejectWithValue(error?.response?.data);
  }
});

export const getEcosystemActorData = createAsyncThunk(
  "auth/getEcosystemActorData",
  async (props, { rejectWithValue }) => {
    // console.log("adminLogin", data);
    const { url, req_body } = props;
    try {
      const response = await createAxios().post(url, req_body);
      return response?.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const aboutInfoRequest = createAsyncThunk("auth/aboutInfoRequest", async (props, { rejectWithValue }) => {
  // console.log("adminLogin", data);
  const { url, req_body = null } = props;
  try {
    const response = await createAxios().get(url, req_body);
    return response?.data;
  } catch (error) {
    return rejectWithValue(error?.response?.data);
  }
});

export const getNewToken = createAsyncThunk("auth/getNewToken", async (props, { rejectWithValue }) => {
  // console.log("adminLogin", data);
  const { url, req_body } = props;
  try {
    const response = await createAxios().post(url, req_body);
    return response?.data;
  } catch (error) {
    return rejectWithValue(error?.response?.data);
  }
});

export const getActorData = createAsyncThunk("auth/getActorData", async (props, { rejectWithValue }) => {
  const { url, token } = props;
  const req_url = `${url}/actor/me`;

  try {
    const response = await createAxios(token).get(req_url, null, {
      headers: {
        "Session-token": token
      }
    });
    return response?.data;
  } catch (error) {
    return rejectWithValue(error?.response?.data);
  }
});

export const rootAPT54Auth = createAsyncThunk("auth/rootAPT54Auth", async (props, { rejectWithValue }) => {
  const { url, req_data } = props;
  const req_url = `${url}/apt54/root/`;

  try {
    const response = await createAxios().post(req_url, req_data);
    return response?.data;
  } catch (error) {
    return rejectWithValue(error?.response?.data);
  }
});

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    current_user: !!localStorage.getItem("current_user") ? JSON.parse(localStorage.getItem("current_user")) : null,
    all_users: !!localStorage.getItem("all_users") ? JSON.parse(localStorage.getItem("all_users")) : [],
    selected_user_info: null,
    loading: false
  },
  reducers: {
    // --------------- user ------------------------

    setCurrentUserByUUID: (state, action) => {
      const uuid = action.payload;
      const newCurrentUser = state.all_users.find((user) => user?.user_uuid === uuid);
      state.current_user = newCurrentUser;
      localStorage.setItem("current_user", JSON.stringify(newCurrentUser));
    },
    setCurrentUser: (state, action) => {
      // console.log("setCurrentUser", action.payload);
      state.current_user = action.payload;
      localStorage.setItem("current_user", JSON.stringify(action.payload));
    },
    addNewUser: (state, action) => {
      // console.log("addNewUser", action.payload);
      const checkIsAlreadyUser = state.all_users.slice(0).some((user) => user?.user_uuid === action.payload?.user_uuid);
      if (!checkIsAlreadyUser) {
        const newUsersData = [...state.all_users, action.payload];
        // console.log("checkIsAlreadyUser", newUsersData);
        state.all_users = newUsersData;
        localStorage.setItem("all_users", JSON.stringify(newUsersData));
      }
    },
    updateAccountInfo: (state, action) => {
      // console.log("updateAccountInfo", action.payload);
      const user_uuid = action?.payload?.user_uuid;
      const defaultUser = state.all_users.find((user) => user_uuid === user?.user_uuid);
      const newUserInfo = { ...defaultUser, ...action?.payload };
      const newAllUsersData = state.all_users.map((user) =>
        user_uuid === user?.user_uuid ? { ...newUserInfo } : { ...user }
      );
      // console.log("newAllUsersData", newAllUsersData);
      state.all_users = newAllUsersData;
      state.selected_user_info = newUserInfo;
      localStorage.setItem("all_users", JSON.stringify(newAllUsersData));
    },
    deleteUser: (state, action) => {
      const deletingUuid = action?.payload;
      const newAllUsersData = state.all_users.slice(0).filter((item) => item?.user_uuid !== deletingUuid) || [];
      state.all_users = newAllUsersData;
      if (!!newAllUsersData?.length) {
        state.current_user = newAllUsersData[0];
        localStorage.setItem("all_users", JSON.stringify(newAllUsersData));
        localStorage.setItem("current_user", JSON.stringify(newAllUsersData[0]));
      } else {
        state.current_user = null;
        localStorage.removeItem("all_users");
        localStorage.removeItem("current_user");
        // const origin = window.location.origin;
        // window.location.replace(`${origin}/`);
      }
    },
    setSelectedUserInfo: (state, action) => {
      const uuid = action.payload;
      const account = state.all_users.slice(0).find((user) => user?.user_uuid === uuid);
      // console.log("account", account);
      state.selected_user_info = account;
    },
    resetSelectedUserInfo: (state) => {
      state.selected_user_info = null;
    },

    // --------------- BACKUP ----------------------

    setBackupData: (state, action) => {
      // console.log("setBackupData", action?.payload);
      const checkIsValidBackup =
        !!action?.payload?.user_uuid && (!!action?.payload?.user_priv_key || !!action?.payload?.password);
      if (checkIsValidBackup) {
        const newCurrentUserData = action?.payload;
        const checkOtherAccounts = !!state.all_users.length;
        const checkIsAlreadyInApp = state.all_users.some(
          (account) => account?.user_uuid === newCurrentUserData?.user_uuid
        );
        // console.log("checkIsAlreadyInApp", checkIsAlreadyInApp);

        if (!checkOtherAccounts) {
          // console.log("checkOtherAccounts", newCurrentUserData);
          state.current_user = newCurrentUserData;
          localStorage.setItem("current_user", JSON.stringify(newCurrentUserData));
        }
        const newAllUsersData = [...state.all_users.slice(0), newCurrentUserData];
        // console.log("newAllUsersData", newAllUsersData?.length);
        // console.log("newAllUsersData length", newAllUsersData?.length);
        state.all_users = newAllUsersData;
        localStorage.setItem("all_users", JSON.stringify(newAllUsersData));
      }
    },
    logout: (state) => {
      localStorage.clear();
      const origin = window.location.origin;
      window.location.replace(`${origin}/`);
    },
    setLoginState: (state, action) => {
      state.loginState = action.payload;
    }
  },
  extraReducers: {
    // ---------- createNewUser -----------------
    [createNewUser.pending]: (state, action) => {
      state.loading = true;
    },
    [createNewUser.fulfilled]: (state, action) => {
      state.loading = false;
      state.user_uuid = action?.payload;
    },
    [createNewUser.rejected]: (state, action) => {
      state.loading = false;
      state.error = action?.payload?.message;
    },
    // ---------- refresh_token (for current active account and active office) -----------------
    [signIn.pending]: (state, action) => {
      state.loading = true;
    },
    [signIn.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [signIn.rejected]: (state, action) => {
      state.loading = false;
      state.error = action?.payload?.message;
    },
    // ---------- getSalt -----------------
    [createNewSalt.pending]: (state, action) => {
      state.loading = true;
    },
    [createNewSalt.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [createNewSalt.rejected]: (state, action) => {
      state.loading = false;
      state.error = action?.payload?.message;
    },
    // ---------- getEcosystemActorData -----------------
    [getEcosystemActorData.pending]: (state, action) => {
      state.loading = true;
    },
    [getEcosystemActorData.fulfilled]: (state, action) => {
      state.loading = false;
      // console.log("getEcosystemActorData", action?.payload);
    },
    [getEcosystemActorData.rejected]: (state, action) => {
      state.loading = false;
      state.error = action?.payload?.message;
    },
    // ---------- about -----------------
    [aboutInfoRequest.pending]: (state, action) => {
      state.loading = true;
    },
    [aboutInfoRequest.fulfilled]: (state, action) => {
      state.loading = false;
      // console.log("aboutInfoRequest", action?.payload);
    },
    [aboutInfoRequest.rejected]: (state, action) => {
      state.loading = false;
      state.error = action?.payload?.message;
    },
    // ---------- getNewToken -----------------
    [getNewToken.pending]: (state, action) => {
      state.loading = true;
    },
    [getNewToken.fulfilled]: (state, action) => {
      state.loading = false;
      // console.log("getNewToken", action?.payload);
    },
    [getNewToken.rejected]: (state, action) => {
      state.loading = false;
      state.error = action?.payload?.message;
    },
    // ---------- getActorData -----------------
    [getActorData.pending]: (state, action) => {
      state.loading = true;
    },
    [getActorData.fulfilled]: (state, action) => {
      state.loading = false;
      // console.log("getNewToken", action?.payload);
    },
    [getActorData.rejected]: (state, action) => {
      state.loading = false;
      state.error = action?.payload?.message;
    },
    // ---------- rootAPT54Auth -----------------
    [rootAPT54Auth.pending]: (state, action) => {
      state.loading = true;
    },
    [rootAPT54Auth.fulfilled]: (state, action) => {
      state.loading = false;
      // console.log("getNewToken", action?.payload);
    },
    [rootAPT54Auth.rejected]: (state, action) => {
      state.loading = false;
      state.error = action?.payload?.message;
    }
  }
});
export const {
  setCurrentUser,
  addNewUser,
  deleteUser,
  setBackupData,
  logout,
  setLoginState,
  setSelectedUserInfo,
  resetSelectedUserInfo,
  setCurrentUserByUUID,
  updateAccountInfo
} = authSlice.actions;
export default authSlice.reducer;
