import { useDispatch, useSelector } from "react-redux";
import { setCurrentUserByUUID } from "../../redux/auth";
import { setDefaultAccount } from "../../redux/office";
import "./index.css";

import { List, ListItem, Button, ListItemAvatar, Avatar, Chip, ListItemIcon } from "@mui/material";
import { IconButton, Typography, Divider, ListItemButton, ListItemText } from "@mui/material";
import { DialogWindow } from "../DialogWindow/DialogWindow";

import StarRateIcon from "@mui/icons-material/StarRate";
import CloseIcon from "@mui/icons-material/Close";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import PublicIcon from "@mui/icons-material/Public";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";

import { openNotify } from "../OpenNotify/openNotify";

const style = {
  position: "absolute",
  top: "10vh",
  left: "16px",
  width: "calc(100vw - 32px)",
  maxHeight: "80vh",
  bgcolor: "background.paper",
  // border: "2px solid #000",
  borderRadius: "16px",
  boxShadow: 24,
  p: 0
};

export const ModalAccounts = (props) => {
  const dispatch = useDispatch();
  const { showModal, setShowModal, handleOpenSignInScaner } = props;
  const { all_users, current_user } = useSelector((state) => state.auth);
  const { selected_office_info } = useSelector((state) => state.office);

  const availableUsers = all_users.filter(
    (user) => (user?.biom?.biom_uuid || user?.biom?.uuid) === selected_office_info?.biom_uuid
  );

  const default_account = all_users.find((user) => user?.user_uuid === selected_office_info?.default_account);

  // const handleMakeActive = (user) => {
  //   if (user?.user_uuid !== current_user?.user_uuid) {
  //     dispatch(setCurrentUserByUUID(user?.user_uuid));
  //     const fn = user?.first_name;
  //     const ln = user?.last_name;
  //     openNotify("success", `Account ${fn} ${ln} is selected as active`);
  //   }
  // };

  const handleNewDefaultAccount = (user) => {
    if (user?.user_uuid !== selected_office_info?.default_account) {
      const data = { user_uuid: user?.user_uuid, service_uuid: selected_office_info?.service_uuid };
      dispatch(setDefaultAccount(data));
      const fn = user?.first_name;
      const ln = user?.last_name;
      openNotify("success", `Account ${fn} ${ln} is selected as the default account`);
    }
  };

  const handleCloseDialog = () => {
    setShowModal(false);
  };

  const handleSignIn = () => {
    dispatch(setCurrentUserByUUID(default_account?.user_uuid));
    handleOpenSignInScaner(default_account);
  };

  const content = (
    <>
      <div
        style={{ padding: "8px 10px 0 16px", display: "flex", justifyContent: "space-between", alignItems: "center" }}
      >
        <Typography variant="subtitle1">Available accounts</Typography>

        <IconButton onClick={handleCloseDialog}>
          <CloseIcon />
        </IconButton>
      </div>
      <Divider />

      <List sx={{ position: "relative", maxHeight: "calc(80vh - 60px)", overflow: "auto" }}>
        <ListItem sx={{ position: "sticky", top: "-8px", zIndex: "10", background: "white" }}>
          <Button
            fullWidth
            sx={{ height: "48px" }}
            variant="contained"
            startIcon={<QrCodeScannerIcon />}
            onClick={handleSignIn}
          >
            Sign in with a default account
          </Button>
        </ListItem>

        {availableUsers.map((user) => {
          // console.log("user", user);

          const email = user?.email;
          const biom_name = user?.biom?.name || user?.biom?.biom_name;
          // const biom_domain = domain;
          const fn = user?.first_name[0];
          const ln = user?.last_name[0];
          const checkIsActive = user?.user_uuid === default_account?.user_uuid;
          // <Chip label="Active" color="success" size="small" />
          return (
            <ListItemButton key={`item_${user?.user_uuid}`} onClick={() => handleNewDefaultAccount(user)}>
              <ListItem
                disablePadding
                secondaryAction={
                  <div style={{ marginRight: "-24px" }}>
                    <IconButton>
                      {checkIsActive ? (
                        <CheckCircleIcon color="primary" sx={{ fontSize: "36px" }} />
                      ) : (
                        <RadioButtonUncheckedIcon color="primary" sx={{ fontSize: "36px" }} />
                      )}
                    </IconButton>
                  </div>
                }
              >
                <ListItemAvatar>
                  <Avatar sx={{ background: checkIsActive ? "rgba(25, 118, 210, .8)" : "#bdbdbd" }}>
                    {fn}
                    {ln}
                  </Avatar>
                </ListItemAvatar>
                <div>
                  <div className="modal_accounts_info_wrapper">
                    <AlternateEmailIcon color="neutral" sx={{ marginRight: "6px" }} />
                    <div className="modal_accounts_text_wrapper">{email}</div>
                  </div>
                  <div className="modal_accounts_info_wrapper">
                    <PublicIcon color="neutral" sx={{ marginRight: "6px" }} />
                    <div className="modal_accounts_text_wrapper"> {biom_name}</div>
                  </div>
                </div>
              </ListItem>
            </ListItemButton>
          );
        })}
        {/* <div style={{ height: "200px" }}></div> */}
        {/* <div style={{ height: "100vh" }}></div> */}
      </List>
    </>
  );
  return <DialogWindow children={content} open={showModal} setOpen={setShowModal} styleProps={style} />;
};
