import axios from "axios";

import { baseUrl } from "../Config/config";

import { store } from "../redux/store";
import { setType, setMessage, setIsOpen, setIsTrustedUrlError, setPasswordErrorData } from "../redux/notify";
import { checkConnection } from "./checkConnection";

import { handlePasswordError } from "../utils/handlePasswordError";
import { pub_ssl } from "./pub_ssl";

const openNotificationWithIcon = (type, error) => {
  const message = `Error ${error?.response?.status}: ${
    error?.response?.data?.message || error?.response?.data?.error_message || ""
  }`;
  // console.log("openNotificationWithIcon", message);
  const dispatch = store.dispatch;
  dispatch(setIsOpen(true));
  dispatch(setType(type));
  dispatch(setMessage(message));
};

const handleTrustedUrl = async (error) => {
  const check = checkConnection();
  // console.log("handleTrustedUrl check", error?.config?.url);
  const url = new URL(error?.config?.url).origin;

  if (check) {
    const dispatch = store.dispatch;
    dispatch(setIsTrustedUrlError([url]));
  }
};

export const createAxios = (props) => {
  // console.log("props", props);

  const headers = !!props ? { "Session-token": props } : {};
  const newInstance = axios.create({
    headers: { ...headers, "Access-Control-Allow-Origin": "*" }
  });

  newInstance.interceptors.request.use(
    (config) => {
      // console.log("success1", config);
      return config;
    },
    (error) => {
      // console.log("error1", error);

      return Promise.reject(error);
    }
  );
  newInstance.interceptors.response.use(
    (config) => {
      // console.log("success2");
      return config;
    },
    (error) => {
      checkConnection();
      // console.log("error2", error);
      if (!error?.response?.data && error?.code === "ERR_NETWORK") {
        handleTrustedUrl(error);
      }

      if (error?.response && error?.response.status >= 400 && error?.response.status < 500) {
        if (error?.response?.data?.error_message === "Password verification failed.") {
          handlePasswordError(error?.response);
        }
        if (error?.response.status === 401) {
          // console.log("401 401", error);
          // console.log("error", error);
          // const dispatch = store.dispatch;
          // dispatch(logout());
        }
        openNotificationWithIcon("error", error);
        return Promise.reject(new Error("Bad status code"));
      } else if (error?.response && error?.response.status >= 500 && error?.response.status < 600) {
        openNotificationWithIcon("error", error);
        return Promise.reject(new Error("Internal server error"));
      } else {
        if (!!error?.response?.data && !(error?.code === "ERR_NETWORK")) {
          openNotificationWithIcon("error", error);
          return Promise.reject(error);
        }
      }
    }
  );

  return newInstance;
};
