import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { deleteUser, setCurrentUserByUUID } from "../../../redux/auth";
import { setDefaultAccount } from "../../../redux/office";
import { rootAPT54Auth } from "../../../redux/auth";

import { setShowAllAccounts } from "../../../redux/switcher";

import { Card, CardHeader, CardContent, Typography, Button, Collapse } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { ListItem, ListItemIcon, ListItemText, ListItemButton } from "@mui/material";
import { ExpandTitle } from "../../../components/ExpandTitle/ExpandTitle";
import { ConfirmModal } from "../../../components/ConfirmModal/ConfirmModal";
import { ModalOffices } from "../../../components/ModalOffices/ModalOffices";
import FullScreenDialog from "../../../components/FullScreenDialog/FullScreenDialog";
import { BackupCreateForm } from "../../../components/BackupCreateForm/BackupCreateForm";
import { decryptBackup } from "../../../utils/decryptBackup";

import BackupIcon from "@mui/icons-material/Backup";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import StarIcon from "@mui/icons-material/Star";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import SyncIcon from "@mui/icons-material/Sync";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";

import { openNotify } from "../../../components/OpenNotify/openNotify";
import { scannerType } from "../../../utils/constants";

import { handleToken } from "../../../utils/handleToken";
import { handleUpdateAccountInfo } from "../../../utils/handleUpdateAccountInfo";
import { createRootAPT54 } from "../../../utils/createRootAPT54";
import { RESET_TOKEN, ROOT_APT } from "../../../components/ModalOffices/ModalOffices";
import { sign_in_gen } from "../../../utils/sign_in_gen";
import { createAtTimeParse } from "../../../utils/createAtTimeParse";
import { appRoutes } from "../../../utils/constants";
import { checkAvailableUrl } from "../../../utils/checkAvailableUrl";
import { setIsTrustedUrlError } from "../../../redux/notify";

export const ActionsBlock = (props) => {
  const { handleOpenSignInDialog, user_priv_key, user_uuid, first_name, last_name, biom, actor_type, root } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { all_offices } = useSelector((state) => state.office);
  const { selected_user_info } = useSelector((state) => state.auth);

  const availableOffices = all_offices.filter((office) => office?.biom_uuid === (biom?.uuid || biom?.biom_uuid));

  const checkIsClassicUser = actor_type === "classic_user";

  const [openCreateBackup, setOpenCreateBackup] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [showMore, setShowMore] = useState(true);

  const [modalOfficesType, setModalOfficesType] = useState(RESET_TOKEN);
  const [showOfficesModal, setShowOfficesModal] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [loadingReset, setLoadingReset] = useState(false);
  const [loadingRootAuth, setLoadingRootAuth] = useState(false);

  const handleShowMore = () => {
    setShowMore((prev) => !prev);
  };

  const handleOpenDialog = () => {
    setOpenDelete(true);
  };

  const checkIsDefaultAccount = (user_uuid) => {
    availableOffices.forEach((office) => {
      // console.log("office?.default_account", office?.default_account);
      // console.log("user_uuid ===", office?.default_account === user_uuid);
      if (office?.default_account === user_uuid) {
        const data = { user_uuid: "", service_uuid: office?.service_uuid };
        dispatch(setDefaultAccount(data));
      }
    });
  };

  const handleDeleteAccount = (bool) => {
    if (bool) {
      checkIsDefaultAccount(user_uuid);
      navigate(appRoutes.accounts.path);
      dispatch(deleteUser(user_uuid));
      openNotify("success", "The account has been successfully deleted");
      dispatch(setShowAllAccounts(true));
    }
  };

  const text =
    "You are deleting your account. Make sure that a backup has been created for recovery. Confirm deletion?";

  const handleCreateBackup = () => {
    setOpenCreateBackup(true);
  };

  const handleResetToken = async () => {
    try {
      setLoadingReset(true);
      const resp = await handleToken({ ...props }, availableOffices[0], true);
      if (!!resp) {
        openNotify(
          "success",
          `The token has been successfully updated for ${
            availableOffices[0]?.name_comment || availableOffices[0]?.name
          }`
        );
      }
    } catch (e) {
    } finally {
      setLoadingReset(false);
    }
  };

  const handleOfficeModal = () => {
    const checkIsManyOffices = availableOffices.length > 1;
    if (!availableOffices?.length) {
      openNotify("error", "There are no relays available for this account");
      return null;
    }
    if (checkIsManyOffices) {
      setShowOfficesModal(true);
    }
    return checkIsManyOffices;
  };

  const handleResetOfficeToken = async () => {
    setModalOfficesType(RESET_TOKEN);
    const check = handleOfficeModal();
    // console.log("handleResetOfficeToken", check);
    if (!check) {
      handleResetToken();
    }
  };

  const handleSession = async (office) => {
    try {
      setLoadingReset(true);
      const resp = await handleToken({ ...props }, office, true);
      if (!!resp) {
        openNotify("success", `The token has been successfully updated for ${office?.name_comment || office?.name}`);
      }
    } catch (e) {
      console.log("e", e);
    } finally {
      setLoadingReset(false);
    }
  };

  const handleUpdate = async () => {
    try {
      setLoadingUpdate(true);
      await handleUpdateAccountInfo({ ...props });
      // .then(() =>
      //   openNotify("success", `Account information has been successfully updated`)
      // );
    } catch (e) {
    } finally {
      setLoadingUpdate(false);
    }
  };

  const handleRoot54 = async (office) => {
    const availableUrl = await checkAvailableUrl(office?.address[0], office?.address[1]);
    if (!availableUrl) {
      // openNotify("error", `Authentication error`);
      dispatch(setIsTrustedUrlError([office?.address[0], office?.address[1]]));
      return null;
    }
    const user_pub_key = (await sign_in_gen("123", selected_user_info?.user_priv_key)).pub;
    const user_data = {
      actor_type: selected_user_info?.actor_type,
      created: createAtTimeParse(selected_user_info?.created, "YYYY-MM-DD HH:mm:ss"),
      initial_key: user_pub_key,
      root_perms_signature: selected_user_info?.root_perms_signature,
      secondary_keys: null,
      uinfo: {
        email: selected_user_info?.email,
        first_name: selected_user_info?.first_name,
        groups: selected_user_info?.groups,
        last_name: selected_user_info?.last_name
      },
      uuid: selected_user_info?.user_uuid
    };

    const aptData = {
      user_data: user_data,
      user_priv_key: user_priv_key
    };

    // setModalOfficesType(RESET_TOKEN);

    const resp_apt54 = await createRootAPT54(aptData);
    console.log("resp_apt54", resp_apt54);
    const reqData = {
      url: availableUrl,
      req_data: resp_apt54
    };
    // console.log("reqData", reqData);
    const resp = await dispatch(rootAPT54Auth(reqData)).unwrap();
    // console.log("resp", resp);
    if (!!resp) {
      openNotify("success", `Authenticated on the ${office?.name_comment || office?.name} service`);
    } else {
      openNotify("error", `Authentication error`);
    }
  };

  const root54Data = () => {
    setModalOfficesType(ROOT_APT);

    const check = handleOfficeModal();
    if (!check) {
      handleAPT54();
    }
  };

  const handleAPT54 = async () => {
    try {
      setLoadingRootAuth(true);

      const resp = await handleRoot54(availableOffices[0]);

      if (!!resp) {
        console.log("resp", resp);
        openNotify(
          "success",
          `Authenticated on the ${availableOffices[0]?.name_comment || availableOffices[0]?.name} service`
        );
      }
    } catch (e) {
      openNotify("error", `Authentication error`);
    } finally {
      setLoadingRootAuth(false);
    }
  };

  return (
    <Card
      elevation={0}
      sx={{
        overflow: "visible",
        // height: "70vh",
        marginTop: "6px",
        background: "rgba(0, 0, 0, 0.06)",
        "& .MuiCardHeader-root": {
          padding: "8px 16px"
        }
      }}
    >
      <CardHeader title={<Typography variant="title1">Account actions</Typography>} />
      <CardContent sx={{ padding: "0px 16px" }}>
        {!checkIsClassicUser && (
          <>
            <div style={{ margin: "8px 0 0 0" }}>
              <Button
                variant="contained"
                fullWidth
                onClick={() => handleOpenSignInDialog(scannerType.sign_in)}
                startIcon={<QrCodeScannerIcon />}
                size="large"
              >
                Sign in
              </Button>
            </div>
            <div style={{ margin: "16px 0 0 0" }}>
              <ExpandTitle
                label={"More actions"}
                labelHide={"Hide actions"}
                isOpen={showMore}
                handleShowMore={handleShowMore}
              />
            </div>
          </>
        )}

        <Collapse in={showMore} timeout="auto" sx={{ transition: ".3s" }}>
          <div style={{ width: "100%" }}>
            {root && (
              <div
                style={{
                  margin: "8px 0",
                  display: "flex",
                  justifyContent: "space-between",
                  flexWrap: "nowrap"
                }}
              >
                <LoadingButton
                  loading={loadingRootAuth}
                  fullWidth
                  variant="outlined"
                  startIcon={<AdminPanelSettingsIcon />}
                  onClick={root54Data}
                >
                  Root authentication
                </LoadingButton>
              </div>
            )}
            <div style={{ margin: "8px 0", display: "flex", justifyContent: "space-between", flexWrap: "nowrap" }}>
              <LoadingButton
                variant="outlined"
                sx={{ width: "calc(50% - 8px)" }}
                startIcon={<SyncIcon />}
                color="info"
                onClick={handleUpdate}
                loading={loadingUpdate}
              >
                Update info
              </LoadingButton>
              <LoadingButton
                variant="outlined"
                sx={{ width: "calc(50% - 8px)" }}
                startIcon={<RestartAltIcon />}
                color="info"
                onClick={handleResetOfficeToken}
                loading={loadingReset}
              >
                Reset token
              </LoadingButton>
            </div>

            <div
              style={{
                margin: "8px 0",
                display: "flex",
                justifyContent: "space-between",
                flexWrap: "nowrap"
              }}
            >
              <Button fullWidth variant="contained" startIcon={<BackupIcon />} onClick={handleCreateBackup}>
                Backup copy
              </Button>
            </div>

            <div style={{ margin: "8px 0", display: "flex", justifyContent: "space-between", flexWrap: "nowrap" }}>
              <Button variant="contained" sx={{ width: "calc(50% - 8px)" }} startIcon={<InsertLinkIcon />} disabled>
                Attached services
              </Button>
              <Button
                variant="contained"
                sx={{ width: "calc(50% - 8px)" }}
                startIcon={<DeleteForeverIcon />}
                color="error"
                onClick={handleOpenDialog}
              >
                Delete account
              </Button>
            </div>
          </div>
        </Collapse>
      </CardContent>
      <ModalOffices
        type={modalOfficesType}
        showModal={showOfficesModal}
        setShowModal={setShowOfficesModal}
        handleSession={handleSession}
        handleRoot54={handleRoot54}
      />

      <ConfirmModal
        open={openDelete}
        setOpen={setOpenDelete}
        callback={handleDeleteAccount}
        isDelete={true}
        text={text}
      />
      <FullScreenDialog
        children={
          <BackupCreateForm
            callbackClose={setOpenCreateBackup}
            uuid={user_uuid}
            first_name={first_name}
            last_name={last_name}
          />
        }
        open={openCreateBackup}
        setOpen={setOpenCreateBackup}
      />
    </Card>
  );
};
