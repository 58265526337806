import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setOfficeCommentData } from "../../redux/office";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import { FormTextField } from "../FormTextField/FormTextField";
import { ListItem } from "@mui/material";

export const OfficeTitleForm = (props) => {
  const dispatch = useDispatch();
  const { title, service_uuid } = props;

  const defaultValues = {
    title: title
  };

  const validationSchema = Yup.object().shape({
    title: Yup.string().test("is_max", "Maximum of 16 characters", (val, values) => val?.length <= 16)
  });

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    reValidateMode: "onChange",
    mode: "onSubmit",
    shouldFocusError: true,
    defaultValues: {
      ...defaultValues
    }
  });

  const {
    watch,
    register,
    reset,
    setValue,
    handleSubmit,
    formState: { errors, isSubmitting, isSubmitted, isSubmitSuccessful }
  } = methods;

  useEffect(() => {
    reset({ ...defaultValues });
  }, [title]);

  const onSubmit = (data) => {
    const finalData = {
      service_uuid: service_uuid,
      data: { name_comment: data?.title }
    };
    dispatch(setOfficeCommentData(finalData));
  };

  return (
    <>
      <FormProvider {...methods}>
        <div className="office_form_field_wrapper">
          <FormTextField
            type="text"
            isVisible={true}
            fieldLabel={"Title"}
            fieldName={"title"}
            maxLength={16}
            isDisabled={false}
            isCountChar={true}
            isOfficeCard={true}
            callbackOfficeCard={handleSubmit(onSubmit)}
          />
        </div>
      </FormProvider>
    </>
  );
};
