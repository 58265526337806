import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setShowAllOffices, setShowAllAccounts } from "../../redux/switcher";
import { useLayoutEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Typography, IconButton, Divider, Container } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import "./index.css";

import { appRoutes } from "../../utils/constants";

export const Header = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { selected_user_info } = useSelector((state) => state.auth);
  const { selected_office_info, current_office } = useSelector((state) => state.office);

  const [currentPage, setCurrentPage] = useState("");

  const detectPage = () => {
    const currentLoc = location.pathname;
    switch (currentLoc) {
      case appRoutes.account.path: {
        const fn = selected_user_info?.first_name;
        const ln = selected_user_info?.last_name;
        return `${fn} ${ln}`;
      }
      case appRoutes.accounts.path: {
        return appRoutes.accounts.title;
      }
      case appRoutes.relays.path: {
        return appRoutes.relays.title;
      }
      case appRoutes.doors.path: {
        return `${selected_office_info?.name_comment || selected_office_info?.name || current_office?.name} doors`;
      }
      case appRoutes.settings.path: {
        return appRoutes.settings.title;
      }
      default:
        return ``;
    }
  };

  useLayoutEffect(() => {
    setCurrentPage(detectPage());
  }, [location.pathname]);

  const handleBack = () => {
    // console.log("handle back", location.pathname);
    if (location.pathname === appRoutes.account.path) {
      // dispatch(setShowAllAccounts(true));
      navigate(appRoutes.accounts.path);
    }
    if (location.pathname === appRoutes.doors.path) {
      // dispatch(setShowAllOffices(true));
      navigate(appRoutes.relays.path);
    }
  };

  return (
    <>
      <div className="page_header">
        <div className="header_inner">
          {(location.pathname === appRoutes.account.path || location.pathname === appRoutes.doors.path) && (
            <ChevronLeftIcon color="primary" onClick={handleBack} sx={{ position: "relative", top: "3px" }} />
          )}
          <div className="header_title" onClick={handleBack}>
            <Typography variant="subtitle1" sx={{ margin: "0 0", padding: "0 0", lineHeight: "1.1" }}>
              <div className="title_truncate">{currentPage}</div>
            </Typography>
          </div>
        </div>

        {/* <IconButton color="primary">
          <MoreVertIcon />
        </IconButton> */}
      </div>
    </>
  );
};
