import { useSelector, useDispatch } from "react-redux";
import { useState } from "react";

import { Button, Avatar, Typography } from "@mui/material";
import { ScannerComponent } from "../../components/Scaner/ScannerComponent";
import FullScreenDialog from "../../components/FullScreenDialog/FullScreenDialog";

import { ModalAccounts } from "../../components/ModalAccounts/ModalAccounts";
import { ModalPasswordError } from "../../components/ModalPasswordError/ModalPasswordError";

import { DoorConfigs } from "../../components/DoorConfigs/DoorConfigs";

import { scannerType } from "../../utils/constants";

import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import { isIOS } from "react-device-detect";

import "./index.css";

export const DoorsPage = () => {
  const { all_users } = useSelector((state) => state.auth);
  const { selected_office_info } = useSelector((state) => state.office);
  const { showAllOffices } = useSelector((state) => state.switcher);

  const [openDialog, setOpenDialog] = useState(false);
  const [openAccDialog, setOpenAccDialog] = useState(false);

  const [currentScannerType, setCurrentScannerType] = useState(scannerType.office);

  const scanConfig = <ScannerComponent type={currentScannerType} callbackClose={setOpenDialog} />;

  const handleOpenAccDialog = () => {
    setOpenAccDialog(true);
  };
  const windowInnerHeight = window.innerHeight;
  const default_account = all_users.find((user) => user?.user_uuid === selected_office_info?.default_account);

  const dataToRender = selected_office_info;
  const isManyPins = dataToRender?.pins?.length > 5;

  const handleOpenSignInScaner = () => {
    setCurrentScannerType(scannerType.sign_in);
    setOpenDialog(true);
    setOpenAccDialog(false);
  };

  // console.log("window.innerHeight", window.innerHeight - 200 - 44);

  const pageContent = (
    <>
      <div style={{ display: "flex", justifyContent: "center", flexWrap: "wrap" }}>
        <div
          className="test_height"
          style={{
            paddingBottom: isManyPins ? "16px" : "0",
            height: `calc(${window.innerHeight}px - 200px ${isIOS ? "- 44px" : ""})`,
            transition: ".3s ease-in-out"
          }}
        >
          <DoorConfigs />
        </div>

        {all_users?.length && (
          // className="fixed_account_btn"
          <div
            style={{
              position: "fixed",
              bottom: "16px",
              right: "8px",
              width: "100%",
              display: "flex",
              justifyContent: "right"
            }}
          >
            <Button variant="contained" onClick={handleOpenAccDialog} startIcon={<PeopleAltIcon />}>
              {default_account?.first_name} {default_account?.last_name}
            </Button>
          </div>
        )}
      </div>
      <FullScreenDialog children={scanConfig} open={openDialog} setOpen={setOpenDialog} />
      <ModalAccounts
        showModal={openAccDialog}
        setShowModal={setOpenAccDialog}
        handleOpenSignInScaner={handleOpenSignInScaner}
      />
      <ModalPasswordError />
    </>
  );
  return pageContent;
};
