import { store } from "../redux/store";
import { setLoading } from "../redux/office";
import { checkUrlWithTimeout } from "./checkUrlWithTimeout";

export const checkAvailableUrl = async (url0, url1) => {
  const dispatch = store.dispatch;
  dispatch(setLoading(true));
  // console.log("handleCheckUrls");
  const external_url = url0;
  const internal_url = url1;
  const urlArr = [external_url, internal_url];
  const resp = (
    await Promise.allSettled(urlArr.map(async (url) => ({ success: await checkUrlWithTimeout(url), url: url })))
  ).map((item) => item?.value);

  const availableUrl = resp.find((item) => item?.success)?.url;
  // console.log("availableUrl", availableUrl);
  dispatch(setLoading(false));
  return availableUrl;
};
