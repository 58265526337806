import { store } from "../redux/store";
import { createNewSalt, getNewToken } from "../redux/auth";

import { sign_in_gen } from "./sign_in_gen";

export const getEcosystemSession = async (url, user_uuid, user_priv_key) => {
  const dispatch = store.dispatch;
  try {
    const base_url = `${url}/auth/`;
    const saltData = {
      url: base_url,
      req_body: {
        step: "identification",
        uuid: user_uuid
      }
    };
    // console.log("saltData", saltData);
    const respSalt = await dispatch(createNewSalt(saltData)).unwrap();
    // console.log("respSalt", respSalt);
    const respGen = await sign_in_gen(respSalt?.salt, user_priv_key);
    // console.log("respGen", respGen);
    const req_body = {
      step: "check_secret",
      signed_salt: respGen.signature,
      uuid: user_uuid
    };
    const data = {
      url: base_url,
      req_body: req_body
    };

    const respToken = await dispatch(getNewToken(data)).unwrap();
    // console.log("respUpdate", respToken);
    return respToken;
    // saveToken(respToken?.session_token, respToken?.expiration, current_user?.user_uuid, about_data?.service_uuid);
  } catch (e) {
    console.log("e", e?.message);
  }
};
