import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setSelectedUserInfo, setCurrentUserByUUID } from "../../../redux/auth";
import { Divider, Chip, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { Card, CardContent, CardActions, ListItemButton, Button } from "@mui/material";
import BadgeIcon from "@mui/icons-material/Badge";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import StarIcon from "@mui/icons-material/Star";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import "./index.css";
import { scannerType } from "../../../utils/constants";
import { openNotify } from "../../../components/OpenNotify/openNotify";
import { appRoutes } from "../../../utils/constants";

export const AccountCard = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { first_name, last_name, email, user_uuid, handleShowAllAccounts, handleOpenSignInDialog, biom, actor_type } =
    props;

  const handleAccountToShow = () => {
    dispatch(setSelectedUserInfo(user_uuid));
    handleShowAllAccounts(user_uuid);
    navigate(appRoutes.account.path);
  };

  const handleBtnClick = () => {
    dispatch(setSelectedUserInfo(user_uuid));
    handleOpenSignInDialog(scannerType.sign_in);
  };

  // const textCss = checkIsActive ? "info_field_name" : "info_field_name not_active";
  const textCss = "info_field_name";
  const checkIsClassicUser = actor_type === "classic_user";

  return (
    <Card
      elevation={0}
      sx={{
        marginBottom: "8px",
        position: "relative",
        // border: `1px solid ${checkIsActive ? "#1976d2" : "gray"}`,
        border: `1px solid #1976d2}`,

        borderRadius: "16px",
        // "& .MuiCard-root": {
        //   marginBottom: "8px"
        // },
        "& .MuiCardContent-root": { padding: "2px 16px" }
      }}
    >
      <CardContent sx={{ paddingBottom: "6px" }}>
        <div
          className="info_field_wrapper"
          style={{
            marginTop: props?.is_admin || props?.root ? "12px" : "0",
            display: "flex",
            alignItems: "center",
            height: "24px"
          }}
        >
          <div className={textCss} style={{ fontSize: "16px" }}>
            {first_name} {last_name}
          </div>
          {props?.root && (
            <Chip
              label={<strong>Root</strong>}
              color="primary"
              size="small"
              sx={{ position: "absolute", top: "4px", left: "16px" }}
            />
          )}
          {props?.is_admin && (
            <Chip
              label={<strong>Admin</strong>}
              color="info"
              size="small"
              sx={{ position: "absolute", top: "4px", left: props?.root ? "64px" : "16px" }}
            />
          )}
        </div>
        <div className="info_field_wrapper">
          <div className={textCss}>{email}</div>
        </div>
        <div className="info_field_wrapper">
          <div className={textCss}>Account type</div> {checkIsClassicUser ? "Classic user" : "Ecosystem54"}
        </div>
        <div className="info_field_wrapper">
          <div className={textCss}>Biom name</div> {biom?.biom_name || biom?.name || <> &ndash;</>}
        </div>
      </CardContent>
      <Divider />
      {!checkIsClassicUser && (
        <div className="acc_card_btn_wrapper">
          <Button variant="outlined" size="round" onClick={handleBtnClick} color={"primary"}>
            <QrCodeScannerIcon />
          </Button>
        </div>
      )}

      <ListItem disablePadding secondaryAction={<ChevronRightIcon color={"primary"} />} onClick={handleAccountToShow}>
        <ListItemButton sx={{ padding: "4px 16px" }}>
          <ListItemIcon>
            {/* <BadgeIcon color={checkIsActive ? "primary" : "neutral"} /> */}
            <BadgeIcon color={"primary"} />
          </ListItemIcon>
          <ListItemText sx={{ color: "#1976d2", fontWeight: "600" }}>Open dashboard</ListItemText>
        </ListItemButton>
      </ListItem>
    </Card>
  );
};
