import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setDefaultAccount } from "../../redux/office";
import { TextField, MenuItem, ListItem } from "@mui/material";
import { OfficeAccountItem } from "../OfficeAccountItem/OfficeAccountItem";

export const OfficeAccountSelect = ({ availableUsers, service_uuid, default_account }) => {
  const dispatch = useDispatch();

  const [currentDefAccount, setCurrentDefAccount] = useState(default_account);

  // console.log("default_account", default_account);
  useEffect(() => {
    setCurrentDefAccount(default_account);
    // console.log(default_account);
  }, [default_account]);

  const handleChange = (e) => {
    setCurrentDefAccount(e.target.value);
    const data = {
      user_uuid: e.target.value,
      service_uuid: service_uuid
    };
    dispatch(setDefaultAccount(data));
  };
  return (
    <ListItem sx={{ padding: "4px 8px" }}>
      <TextField
        select
        value={currentDefAccount}
        label="Default account"
        onChange={handleChange}
        fullWidth
        variant="standard"
        size="small"
        InputLabelProps={{
          shrink: true,
          style: { color: "#1976d2", fontSize: "20px" }
        }}
      >
        <MenuItem key={"no_biom_acc"} value={null} sx={{ margin: "0 0", display: "none" }}>
          No available accounts
        </MenuItem>
        {availableUsers.map((user) => (
          <MenuItem key={user.user_uuid} value={user.user_uuid} sx={{ margin: "0 0" }}>
            <OfficeAccountItem user={user} />
          </MenuItem>
        ))}
      </TextField>
    </ListItem>
  );
};
