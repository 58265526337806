export const scannerType = {
  reg: "reg",
  sign_in: "sign_in",
  office: "office",
  office_classic_reg: "office_classic_reg",
  classic_reg: "classic_reg",
  classic_sign_in: "classic_sign_in"
};

export const appRoutes = {
  relays: {
    path: "/relays",
    title: "Relays",
    title2: "Doors",
    second_group: "Relay doors"
  },
  doors: {
    path: "/doors",
    title: "Relays",
    title2: "Doors",
    second_group: "Relay doors"
  },
  accounts: {
    path: "/accounts",
    title: "Accounts",
    title2: "Account",
    second_group: "Active account"
  },
  account: {
    path: "/account",
    title: "Account",
    title2: "Account",
    second_group: "Active account"
  },
  settings: {
    path: "/settings",
    title: "Settings"
  }
};

export const front_date_with_time = "DD-MM-YYYY HH:mm";
