import { useDispatch, useSelector } from "react-redux";
import { setServicesData } from "../../redux/settings";

import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import { List, ListItem, Button, ListItemAvatar, Avatar, Chip, ListItemIcon } from "@mui/material";
import { IconButton, Typography, Divider, Collapse, Alert } from "@mui/material";
import { DialogWindow } from "../DialogWindow/DialogWindow";
import { FormTextField } from "../FormTextField/FormTextField";

import CloseIcon from "@mui/icons-material/Close";

import { openNotify } from "../OpenNotify/openNotify";
import { useState } from "react";

const style = {
  position: "absolute",
  top: "10vh",
  left: "16px",
  width: "calc(100vw - 32px)",
  maxHeight: "80vh",
  bgcolor: "background.paper",
  // border: "2px solid #000",
  borderRadius: "16px",
  boxShadow: 24,
  p: 0
};

export const ModalSettings = ({ showModal, setShowModal }) => {
  const dispatch = useDispatch();

  const { all_services } = useSelector((state) => state.settings);
  const { all_offices } = useSelector((state) => state.office);

  const defaultValues = {
    url: "",
    name: "",
    biom_name: ""
  };

  const validationSchema = Yup.object().shape({
    url: Yup.string()
      .test("is_already_in_app", "Such a service already exists", (val) => {
        // console.log(all_offices.slice(0).some((office) => office.address[0] === val));
        // console.log(all_services.slice(0).some((service) => service?.url === val));
        return (
          !all_services.slice(0).some((service) => service?.url === val) &&
          !all_offices.slice(0).some((office) => office.address[0] === val)
        );
      })
      .required("Required field"),
    name: Yup.string().required("Required field"),
    biom_name: Yup.string()
  });

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    reValidateMode: "onChange",
    mode: "onSubmit",
    shouldFocusError: true,
    defaultValues: {
      ...defaultValues
    }
  });

  const {
    watch,
    register,
    reset,
    setValue,
    handleSubmit,
    formState: { errors, isSubmitting, isSubmitted, isSubmitSuccessful }
  } = methods;

  const handleCloseDialog = () => {
    setShowModal(false);
  };

  const fieldLabel = (fieldName) => {
    switch (fieldName) {
      case "name":
        return "Service name *";
      case "biom_name":
        return "Biom name";
      case "url":
        return "Service url *";
    }
  };

  const onSubmit = async (values) => {
    try {
      dispatch(setServicesData(values));
      setTimeout(() => {
        setShowModal(false);
        reset({ ...defaultValues });
      }, 1500);
    } catch (e) {
      console.log("e", e);
    }
  };

  const content = (
    <>
      <div style={{ padding: "8px 16px 0 16px", display: "flex", justifyContent: "space-between" }}>
        <Typography variant="subtitle1">Add new service</Typography>
        <IconButton onClick={handleCloseDialog}>
          <CloseIcon />
        </IconButton>
      </div>
      <Divider />
      <FormProvider {...methods}>
        {Object.keys(defaultValues).map((fieldName, idx) => (
          <div style={{ padding: "16px 16px" }} key={`${fieldName}_modal_settings`}>
            <FormTextField
              isDisabled={isSubmitSuccessful}
              isRequired={fieldName !== "biom_name"}
              isVisible={true}
              fieldLabel={fieldLabel(fieldName)}
              fieldName={fieldName}
              type={fieldName === "url" ? "url" : "text"}
              placeholder={fieldName === "url" ? "https://service-url.com" : ""}
            />
          </div>
        ))}
      </FormProvider>

      <ListItem sx={{ marginBottom: "8px" }}>
        <Button fullWidth variant="outlined" onClick={handleSubmit(onSubmit)} disabled={isSubmitSuccessful}>
          Add a service
        </Button>
      </ListItem>
      <Collapse in={isSubmitSuccessful} timeout="auto" sx={{ transition: ".3s" }}>
        <Alert severity="success">Service successfully added</Alert>
      </Collapse>
    </>
  );
  return <DialogWindow children={content} open={showModal} setOpen={setShowModal} styleProps={style} />;
};
