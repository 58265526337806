import { Typography, Button } from "@mui/material";
import { Card, CardContent, CardActionArea } from "@mui/material";

import { useNavigate } from "react-router-dom";
import { appRoutes } from "../../utils/constants";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AddIcon from "@mui/icons-material/Add";

export const NoOfficesSettings = ({ handleShowModal }) => {
  const navigate = useNavigate();

  const handleToRelays = () => {
    navigate(appRoutes.relays.path);
  };

  return (
    <div style={{ marginTop: "48px" }}>
      <CardActionArea sx={{ marginBottom: "16px" }} onClick={handleToRelays}>
        <Card elevation={4} sx={{ height: "120px", display: "flex", justifyContent: "center", alignItems: "center" }}>
          <CardContent>
            <Typography variant="subtitle1" sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
              <ArrowBackIcon sx={{ marginRight: "6px" }} /> To the Relays
            </Typography>
          </CardContent>
        </Card>
      </CardActionArea>
      <CardActionArea onClick={handleShowModal}>
        <Card elevation={4} sx={{ height: "120px", display: "flex", justifyContent: "center", alignItems: "center" }}>
          <CardContent>
            <Typography variant="subtitle1" sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
              <AddIcon sx={{ marginRight: "6px" }} />
              Add trusted service
            </Typography>
          </CardContent>
        </Card>
      </CardActionArea>
      {/* <Typography variant="subtitle1" sx={{ textAlign: "center" }}>
        No trusted relays or services available
      </Typography>
      <Typography sx={{ textAlign: "center" }} variant="subtitle2">
        There are no trusted relays or services to check.
      </Typography>
      <Button fullWidth variant="contained" sx={{ marginTop: "16px" }} onClick={handleToRelays}>
        To the Relays
      </Button>
      <Button fullWidth variant="contained" sx={{ marginTop: "16px" }} onClick={handleShowModal}>
        Specify the service data
      </Button> */}
    </div>
  );
};
