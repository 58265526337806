import { useSelector, useDispatch } from "react-redux";
import { setSelectedUserInfo } from "../../redux/auth";
import { setLoading, checkPins } from "../../redux/office";
import { setIsTrustedUrlError } from "../../redux/notify";
import "./index.css";

import { useState, useEffect } from "react";

import { Typography } from "@mui/material";

import { PinComponent } from "../../Pages/DoorsPage/PinComponent";
import { ConfirmModal } from "../ConfirmModal/ConfirmModal";

import { handleToken } from "../../utils/handleToken";
import { checkFastestUrl } from "../../utils/checkFastestUrl";
import { isIOS } from "react-device-detect";

export const DoorConfigs = () => {
  const dispatch = useDispatch();
  const { all_users } = useSelector((state) => state.auth);
  const { selected_office_info, current_office, loading } = useSelector((state) => state.office);

  const dataToRender = selected_office_info || current_office;
  const current_user = all_users.find((account) => account?.user_uuid === selected_office_info?.default_account);

  const [pinPerms, setPinPerms] = useState(null);
  const [allPinsBlocked, setAllPinsBlocked] = useState(false);

  const [openModal, setOpenModal] = useState(false);
  const [pinNumber, setPinNumber] = useState(null);
  const [modalText, setModalText] = useState("");
  const [modalResult, setModalResult] = useState(null);

  const windowInnerHeight = window.innerHeight;

  const handleCheckPinsPerms = async (url) => {
    try {
      // console.log("url", url);
      const data = {
        url: url,
        user_uuid: selected_office_info?.default_account,
        service_uuid: selected_office_info?.service_uuid,
        pub_ssl: selected_office_info?.certificate
      };
      // const external_url = selected_office_info?.address[0];
      // const internal_url = selected_office_info?.address[1];
      const token = await handleToken(current_user, selected_office_info);
      const resp = await dispatch(checkPins(data)).unwrap();
      // console.log("resp?.block", resp?.block);
      setAllPinsBlocked(!!resp?.block);
      setPinPerms(resp?.pins_status);
    } catch (e) {
      dispatch(setSelectedUserInfo(selected_office_info?.default_account));
      // console.log("handleCheckPinsPerms e", e);
    }
  };

  const handleOpenModal = async (number, modalText) => {
    setOpenModal(true);
    setPinNumber(number);
    setModalText(modalText);
  };

  const handleModalCallback = (bool, number) => {
    if (bool) {
      setModalResult(number);
    }
  };

  const initFunc = async () => {
    const fastestUrl = await checkFastestUrl(selected_office_info?.address[0], selected_office_info?.address[1]);
    // console.log("fastestUrl", fastestUrl);
    if (!fastestUrl) {
      dispatch(setIsTrustedUrlError([selected_office_info?.address[0], selected_office_info?.address[1]]));
      return null;
    }
    // console.log("availableUrl", availableUrl);
    handleCheckPinsPerms(fastestUrl);
  };

  useEffect(() => {
    initFunc();
  }, [current_user?.user_uuid]);

  // console.log("isBlockDisabled={pinPerms?.block && !current_user?.root}", allPinsBlocked ? !current_user?.root : false);

  // console.log(`calc(${windowInnerHeight}px - 240px ${isIOS && "- 44px"})`);

  return (
    <>
      <Typography variant="subtitle1" sx={{ marginBottom: "16px", display: "flex", justifyContent: "center" }}>
        <div style={{ borderBottom: "2px solid #1976d2" }}> {dataToRender?.name_comment || dataToRender?.name}</div>
      </Typography>

      <ConfirmModal
        open={openModal}
        setOpen={setOpenModal}
        callback={handleModalCallback}
        text={modalText}
        pinNumber={pinNumber}
      />
      {Array.isArray(dataToRender?.pins) &&
        dataToRender?.pins?.map((item, idx) => (
          <PinComponent
            key={`${item?.description}_idx`}
            {...item}
            handleOpenModal={handleOpenModal}
            modalResult={modalResult}
            setModalResult={setModalResult}
            pinConfig={!!pinPerms ? pinPerms[idx] : null}
            pinCount={dataToRender?.pins?.length}
            isBlockDisabled={allPinsBlocked ? !current_user?.root : false}
          />
        ))}
    </>
  );
};
