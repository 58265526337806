// -------- reac, lib ----------
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setShowAllAccounts } from "../../redux/switcher";

// -------- components ----------
import { ClassicRegForm, CLASSIC_SIGN_IN, CLASSIC_SIGN_UP } from "../../components/ClassicRegForm/ClassicRegForm";
import { NoAccounts } from "../../components/NoAccounts/NoAccounts";
import { TabPanel } from "../../components/TabPanel/TabPanel";
import { Button, Typography } from "@mui/material";
import FullScreenDialog from "../../components/FullScreenDialog/FullScreenDialog";
import { AccountCard } from "./AccountCard/AccountCard";
import { ScannerComponent } from "../../components/Scaner/ScannerComponent";
import { BackupModal } from "../../components/BackupModal/BackupModal";
import { DialogWindow } from "../../components/DialogWindow/DialogWindow";
import { AddAccountModal } from "./AddAccountModal/AddAccountModal";
import { ModalPasswordError } from "../../components/ModalPasswordError/ModalPasswordError";
// -------- icons ----------

import PersonAddIcon from "@mui/icons-material/PersonAdd";

// -------- const, utils ----------

import { addAccountModalStyle } from "./addAccountModalStyle";
import { scannerType } from "../../utils/constants";

export const AccountsPage = () => {
  const dispatch = useDispatch();
  const { all_users } = useSelector((state) => state.auth);
  const { showAllAccounts } = useSelector((state) => state.switcher);

  const [openDialog, setOpenDialog] = useState(false);
  const [currentScannerType, setCurrentScannerType] = useState(scannerType.sign_in);
  // const [showAllAccounts, setShowAllAccounts] = useState(false);
  const [addAccountModal, setAddAccountModal] = useState(false);
  const [openClassicModal, setOpenClassicModal] = useState(false);
  const [isClassicReg, setIsClassicReg] = useState(false);

  const [backupFile, setBackupFile] = useState(null);
  const [openBackupDialog, setOpenBackupDialog] = useState(false);

  const handleSetBackupFile = (file, fileName) => {
    setOpenBackupDialog(true);
    setBackupFile({ file: file, fileName: fileName });
    setAddAccountModal(false);
  };

  const handleOpenDialog = (scanType) => {
    setCurrentScannerType(scanType);
    setOpenDialog(true);
  };

  const handleOpenAddAccountModal = () => {
    setAddAccountModal(true);
  };

  const handleOpenRegDialog = (type) => {
    setCurrentScannerType(type);
    setOpenDialog(true);
    setAddAccountModal(false);
  };

  const handleShowAllAccounts = () => {
    dispatch(setShowAllAccounts(!showAllAccounts));
  };

  const handleOpenClassicModal = (bool) => {
    setIsClassicReg(bool);
    setAddAccountModal(false);
    setOpenClassicModal(true);
  };

  const scannerComponent = <ScannerComponent type={currentScannerType} callbackClose={setOpenDialog} />;

  const addAccountModalContent = (
    <AddAccountModal
      handleCloseDialog={setAddAccountModal}
      handleOpenRegDialog={handleOpenRegDialog}
      handleSetBackupFile={handleSetBackupFile}
      handleOpenClassicModal={handleOpenClassicModal}
    />
  );

  return (
    <>
      <div className={!!all_users?.length ? "fixed_switch_btn" : "fixed_switch_btn hide"}>
        <Button
          variant="contained"
          sx={{ padding: "8px 16px", borderRadius: "16px 16px 16px 6px" }}
          onClick={handleOpenAddAccountModal}
          startIcon={<PersonAddIcon />}
          // () => handleOpenDialog(scannerType.reg)
          // disabled
        >
          Add an account
        </Button>
      </div>
      <div style={{ marginTop: "56px" }}></div>
      {!all_users?.length && <NoAccounts callback={handleOpenAddAccountModal} />}
      {all_users.map((user) => (
        <AccountCard
          {...user}
          key={user?.user_uuid}
          handleShowAllAccounts={handleShowAllAccounts}
          handleOpenSignInDialog={handleOpenDialog}
        />
      ))}
      <FullScreenDialog
        children={
          <ClassicRegForm type={isClassicReg ? CLASSIC_SIGN_UP : CLASSIC_SIGN_IN} callbackClose={setOpenClassicModal} />
        }
        open={openClassicModal}
        setOpen={setOpenClassicModal}
      />
      <FullScreenDialog children={scannerComponent} open={openDialog} setOpen={setOpenDialog} />
      <DialogWindow
        children={addAccountModalContent}
        open={addAccountModal}
        setOpen={setAddAccountModal}
        styleProps={addAccountModalStyle}
      />
      <BackupModal
        open={openBackupDialog}
        setOpen={setOpenBackupDialog}
        currentFile={backupFile}
        setBackupFile={setBackupFile}
      />
      <ModalPasswordError />
    </>
  );
};
