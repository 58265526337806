export const fetchWithTimeout = async (url, timeout) => {
  let ctrl = new AbortController();
  let signal = ctrl.signal;

  let fetchData = new Promise((resolve, reject) => {
    fetch(`${url}`, { method: "HEAD", signal: signal })
      .then(async (resp) => {
        resolve({ url: url, success: true });
        // console.log("fetch resp", resp);
        // return true;
      })
      .catch((e) => {
        reject({ url: url, success: false });
        // console.log(`e ${url}`, e);
        // const check = e?.message === "Failed to fetch";
        // return false;
      }); // sent error stack trace
  });
  // set timeout
  let timeOut = new Promise((resolve, reject) => {
    setTimeout(async () => {
      ctrl.abort(); // abort fetch request
      reject("timeout");
      return false;
    }, timeout);
  });

  let result = await Promise.race([fetchData, timeOut]);
  // console.log(`fetchWithTimeout`, result);
  return result;
};
