import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Typography } from "@mui/material";
import { Card, CardHeader, CardContent, Divider, IconButton } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { isIOS } from "react-device-detect";

export const PageWrapper = ({ page }) => {
  const location = useLocation();
  const windowInnerHeight = window.innerHeight;
  const checkIsTallFooter = windowInnerHeight > 680 && isIOS;
  return (
    <>
      <Card
        elevation={0}
        sx={{
          marginTop: "43px",
          position: "relative",
          "& .MuiCardContent-root:last-child": {
            paddingBottom: "16px"
          }
        }}
      >
        {/* <CardHeader
          action={
            <>
              <IconButton color="primary">
                <MoreVertIcon />
              </IconButton>
            </>
          }
          title={
            <>
              <Typography variant="subtitle1">
                Relay54 {`${location.pathname?.slice(1)[0].toUpperCase()}${location.pathname?.slice(2)}`}
              </Typography>
            </>
          }
        /> */}

        <CardContent
          sx={{
            paddingBottom: "32px",
            overflow: "auto !important",
            height: isIOS
              ? `calc(${windowInnerHeight}px - ${checkIsTallFooter ? "186px" : "140px"})`
              : `calc(${windowInnerHeight}px - 138px)`
          }}
        >
          {page}
        </CardContent>
      </Card>
    </>
  );
};
