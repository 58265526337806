import * as React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setBackupData } from "../../redux/auth";
import { addNewOffice } from "../../redux/office";
import { setServicesData } from "../../redux/settings";

import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import { FormTextField } from "../FormTextField/FormTextField";
import { BackupUploader } from "../BackupUploader/BackupUploader";
import { TextField, ListItem, Button, Typography, Collapse, ListItemText, ListItemButton } from "@mui/material";
import { Dialog, DialogContent, DialogContentText, DialogTitle, Alert } from "@mui/material";

import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

import { decryptBackup } from "../../utils/decryptBackup";
import { parseOldBackup } from "./parseOldBackup";
import { openNotify } from "../OpenNotify/openNotify";
import { sign_in_gen } from "../../utils/sign_in_gen";

import { appRoutes } from "../../utils/constants";

export function BackupModal({ open, setOpen, currentFile, setBackupFile }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { all_users } = useSelector((state) => state.auth);

  const [fileName, setFileName] = useState("");
  const [finalData, setFinalData] = useState(null);
  const [showPass, setShowPass] = useState(false);

  const [wrongPrivKey, setWrongPrivKey] = useState(false);

  const defaultValues = {
    file: null,
    password: ""
  };

  const validationSchema = Yup.object().shape({
    file: Yup.mixed().required("No file selected"),
    password: Yup.string()
      .test("is_min", "At least 4 characters", (val, values) => val?.length >= 4)
      .required("Required field")
  });

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    reValidateMode: "onChange",
    mode: "onSubmit",
    shouldFocusError: true,
    defaultValues: {
      ...defaultValues
    }
  });

  const {
    watch,
    register,
    reset,
    setValue,
    handleSubmit,
    formState: { errors, isSubmitting, isSubmitted, isSubmitSuccessful }
  } = methods;

  const handleCancel = () => {
    setOpen(false);
    setBackupFile(null);
  };

  const handleConfirm = () => {
    setOpen(false);
    setBackupFile(null);
  };

  const onSubmit = async (values) => {
    try {
      const backupConfig = await decryptBackup(values?.file, values?.password);

      if (!!backupConfig?.authority?.uuid) {
        const checkOldPrivKey = await sign_in_gen("test", backupConfig?.authority?.private_key);
        if (!checkOldPrivKey) {
          console.log("error");
        }
      }

      const checkIsAlreadyInApp = all_users.some(
        (account) => account?.user_uuid === (backupConfig.authority?.uuid || backupConfig?.account?.user_uuid)
      );
      // console.log("checkIsAlreadyInApp", checkIsAlreadyInApp);
      if (!checkIsAlreadyInApp) {
        const backupData = !!backupConfig?.authority ? { account: parseOldBackup(backupConfig) } : backupConfig;
        const checkIsValidBackup = !!backupData?.account;
        if (checkIsValidBackup) {
          setFinalData(backupData);
          // console.log("backupData", backupData);
        } else {
          openNotify("warning", "The backup has an incorrect structure.");
        }
      } else {
        openNotify("warning", "Such an account is already in the application");
      }
    } catch (e) {
      if (e?.message?.includes("private")) {
        // console.log(e?.message);
        setWrongPrivKey(true);
        setFinalData(null);
        setBackupFile(null);
        openNotify(
          "error",
          "This backup is not supported by the application. Please contact your Administrator to replace your account keys."
        );
        return null;
      }
      openNotify("error", "Error during account recovery. Please check the specified password and try again.");
    }
  };

  useEffect(() => {
    if (!!finalData) {
      setTimeout(() => {
        const account_data = finalData?.account;
        const offices_data = Array.isArray(finalData?.offices) ? finalData?.offices : [];
        const services_data = Array.isArray(finalData?.services) ? finalData?.services : [];
        // console.log("account_data", account_data);
        dispatch(setBackupData(account_data));
        offices_data.forEach((office) => dispatch(addNewOffice(office)));
        services_data.forEach((service) => dispatch(setServicesData(service)));
        setFinalData(null);
        setBackupFile(null);
        setOpen(false);
        navigate(appRoutes.accounts.path);
      }, 1500);
    }
  }, [finalData]);

  const text = !!watch("file") ? "Select another file" : "Select a file";

  const handleSetBackupFile = (file, fileName) => {
    // console.log("fileName", fileName);
    setWrongPrivKey(false);

    setFileName(fileName);
    setValue("file", file, { shouldValidate: true, shouldDirty: true });
    setValue("password", "", { shouldValidate: false, shouldDirty: false });
  };

  useEffect(() => {
    if (!!currentFile) {
      setFileName(currentFile?.fileName);
      reset({ ...defaultValues, file: currentFile?.file });
      setWrongPrivKey(false);
      setFinalData(null);
      setBackupFile(null);
    }
  }, [currentFile]);

  const handleDeleteFile = () => {
    setWrongPrivKey(false);
    setFinalData(null);
    setBackupFile(null);
    setFileName("");
    setValue("file", null, { shouldDirty: true, shouldValidate: true });
  };

  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        style: {
          borderRadius: "16px"
        }
      }}
    >
      <DialogTitle id="alert-dialog-title">Account recovery</DialogTitle>
      <DialogContent sx={{ paddingBottom: "36px" }}>
        <DialogContentText id="alert-dialog-description">
          <FormProvider {...methods}>
            {!!finalData ? (
              <Alert severity="success" sx={{ display: "flex", alignItems: "center", fontSize: "16px" }}>
                Account has been successfully restored.
              </Alert>
            ) : (
              <>
                <ListItem disablePadding sx={{ marginBottom: "8px" }}>
                  Select the file to restore and enter the password you specified earlier when creating the file.
                </ListItem>
                <BackupUploader handleSetBackupFile={handleSetBackupFile} text={text} />
                {!!errors?.file && (
                  <div style={{ padding: "4px 16px 8px 16px", color: "#d32f2f" }}>{errors?.file?.message}</div>
                )}

                <Collapse
                  in={!!watch("file")}
                  timeout="auto"
                  sx={{ padding: !!watch("file") ? "8px 0 16px 8px" : "0 0", transition: ".3s" }}
                >
                  <ListItem disablePadding sx={{ justifyContent: "space-between" }}>
                    <ListItemText>
                      <div className="title_truncate">{fileName}</div>
                    </ListItemText>
                    <Button
                      size="round"
                      variant="outlined"
                      color="error"
                      onClick={handleDeleteFile}
                      sx={{ minWidth: "40px", height: "40px", borderRadius: "20px" }}
                    >
                      <DeleteForeverIcon />
                    </Button>
                  </ListItem>
                </Collapse>
                <Collapse in={wrongPrivKey} timeout="auto" sx={{ transition: ".3s" }}>
                  <Alert severity={"warning"} sx={{ marginBottom: "8px", alignItems: "center" }}>
                    This backup is not supported by the application. Please contact your Administrator to replace your
                    account keys.
                  </Alert>
                </Collapse>

                <FormTextField
                  fieldLabel={"Password"}
                  fieldName={"password"}
                  maxLength={16}
                  isVisible={showPass}
                  callbackVisibility={setShowPass}
                  isDisabled={false}
                  isAuto={true}
                />
              </>
            )}
          </FormProvider>
        </DialogContentText>
      </DialogContent>
      {!finalData && (
        <div style={{ padding: "0px 16px 16px 16px", display: "flex" }}>
          <div style={{ width: "50%", padding: "0 8px 0 0" }}>
            <Button onClick={handleCancel} fullWidth variant="contained" color="error">
              Cancel
            </Button>
          </div>
          <div style={{ width: "50%", padding: "0 8px 0 0" }}>
            <Button onClick={handleSubmit(onSubmit)} fullWidth variant="contained" color={"success"}>
              Restore
            </Button>
          </div>
        </div>
      )}
    </Dialog>
  );
}
