import { createSlice } from "@reduxjs/toolkit";

export const settingsSlice = createSlice({
  name: "settings",
  initialState: {
    all_services: !!localStorage.getItem("services") ? JSON.parse(localStorage.getItem("services")) : [],
    isDebugMode: false
  },
  reducers: {
    setServicesData: (state, action) => {
      const checkIsAlreadyInApp = state.all_services.slice(0).some((service) => service?.url === action.payload?.url);

      if (!checkIsAlreadyInApp) {
        const newData = [...state.all_services, action.payload];
        state.all_services = newData;
        localStorage.setItem("services", JSON.stringify(newData));
      }
    },
    deleteService: (state, action) => {
      const newData = state.all_services.slice(0).filter((office) => office?.url !== action?.payload?.url);

      state.all_services = newData;
      localStorage.setItem("services", JSON.stringify(newData));
    },
    setDebugMode: (state, action) => {
      state.isDebugMode = action.payload;
    }
  }
});

export const { setServicesData, deleteService, setDebugMode } = settingsSlice.actions;

export default settingsSlice.reducer;
