import { useState } from "react";
import { ListItem, ListItemIcon, ListItemText, Button } from "@mui/material";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { openNotify } from "../OpenNotify/openNotify";

export const BackupUploader = ({ text, handleSetBackupFile }) => {
  function handleFileUpload(event) {
    const input = event.target;
    const checkFileType = input.files[0]?.type === "text/plain";
    if (!checkFileType) {
      openNotify("error", "The recovery file must be in .txt format");
      return null;
    }

    if ("files" in input && input.files.length > 0) {
      placeFileContent(document.getElementById("content-target"), input.files[0]);
    }
  }

  function placeFileContent(target, file) {
    readFileContent(file)
      .then((content) => {
        target.value = content;
      })
      .catch((error) => console.log(error));
  }

  function readFileContent(file) {
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.onload = (event) => {
        handleSetBackupFile(event.target.result, file?.name);
        // setFileName("");
      };
      reader.onerror = (error) => {
        console.log(error);
      };
      reader.readAsDataURL(file);
    });
  }

  return (
    <Button variant="outlined" fullWidth size="small" component="label">
      <ListItem sx={{ padding: "3px 6px" }}>
        <ListItemIcon sx={{ minWidth: "36px" }}>
          <UploadFileIcon color="primary" />
        </ListItemIcon>
        <ListItemText>{text || "Upload backup file"}</ListItemText>
      </ListItem>
      <input type="file" onChange={handleFileUpload} hidden />
    </Button>
  );
};
