// ------ react, lib -----
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect, useState, useCallback } from "react";

// ------ redux ----------
import { setRegData } from "../../redux/scaner";
import { signIn } from "../../redux/auth";
import { addNewOffice } from "../../redux/office";

// ------ icons ----------
import RefreshIcon from "@mui/icons-material/Refresh";

// ------ components -----
import { ClassicRegForm, CLASSIC_SIGN_IN, CLASSIC_SIGN_UP } from "../ClassicRegForm/ClassicRegForm";
import { Card, CardHeader, CardContent, Divider } from "@mui/material";
import { CloseButton } from "../CloseButton/CloseButton";
import { ScannerFocus } from "./ScannerFocus";
import { ZBarScanner } from "./ZBarScanner";
import { RegForm } from "../RegForm/RegForm";
import { TextBlock } from "../TextBlock/TextBlock";
import { ConfigText } from "../ConfigText/ConfigText";
import { SignInText } from "../SignInText/SignInText";
import { WrongQR } from "../WrongQR/WrongQR";
import { ErrorQR } from "../ErrorQr/ErrorQR";
import { SuccessQR } from "../SuccessQR/SuccessQR";
import { Typography } from "@mui/material";

// ------ const ----------
import { default_reg_fields } from "../../utils/default_reg_config";
import { default_sign_in_config } from "../../utils/default_sign_in_config";
import { default_office_config } from "../../utils/default_office_config";
import { scannerType } from "../../utils/constants";
import { sign_in_gen } from "../../utils/sign_in_gen";

export const ScannerComponent = ({ type, callbackClose }) => {
  const dispatch = useDispatch();
  const { current_user, all_users, selected_user_info, loading: authLoading } = useSelector((state) => state.auth);
  const { all_offices, loading: officeLoading } = useSelector((state) => state.office);
  const [resetCam, setResetCam] = useState(false);

  const [isScanCompleted, setIsScanCompleted] = useState(false);
  const [isScanSuccess, setIsScanSuccess] = useState(false);

  const [qrMessageType, setQrMessageType] = useState("default");
  const [reqError, setReqError] = useState(null);

  const signInTextData = selected_user_info || current_user;

  const handleRegCallback = (data) => {
    // console.log("handleRegCallback");
    dispatch(setRegData(data));
    setQrMessageType("success");
  };

  const signInFunc = async (data) => {
    const req_body = {
      signed_salt: data.signature,
      qr_token: data?.qr_token,
      uuid: signInTextData?.user_uuid,
      depended_map: {}
    };
    const req_data = {
      url: data?.authentication_url,
      req_body: req_body
    };

    return await dispatch(signIn(req_data)).unwrap();
  };

  const signInActions = async (data) => {
    try {
      // console.log("data", data);
      const respGen = await sign_in_gen(data?.salt, signInTextData?.user_priv_key);
      const finalData = { ...data, ...respGen };

      const resp_signIn = await signInFunc({ ...finalData, uuid: signInTextData?.user_uuid });
      return !!resp_signIn;
    } catch (e) {
      console.log("e", e);
    }
  };

  const handleSuccessSignIn = async (data) => {
    try {
      const resp = await signInActions(data);
      if (!!resp) {
        const dependedServicesKeys = !!data?.depended_services ? Object.keys(data?.depended_services) : null;

        const checkDependedServices = !!dependedServicesKeys?.length;
        if (checkDependedServices) {
          dependedServicesKeys.forEach(async (service_key) => {
            // console.log("dependedServicesKeys data", data);
            // console.log("dependedServicesKeys service_key", service_key);
            // console.log("data?.depended_services[service_key]", data?.depended_services[service_key]);
            await signInActions(data?.depended_services[service_key]);
          });
        }
        setQrMessageType("success");
        // setIsSuccessQr(true);
        setTimeout(() => {
          callbackClose(false);
        }, 2000);
      } else {
        setQrMessageType("error");
      }
    } catch (e) {
      // console.log("e", e);
      setReqError(e);
      setQrMessageType("error");
    }
  };

  const addNewOfficeConfig = (data) => {
    const checkIsUniq = !all_offices.some((office) => office?.service_uuid === data?.service_uuid);
    if (checkIsUniq) {
      const default_user_uuid =
        ((current_user?.biom?.uuid || current_user?.biom?.biom_uuid) === data?.biom_uuid && current_user?.user_uuid) ||
        all_users.find((user) => (user?.biom?.uuid || user?.biom?.biom_uuid) === data?.biom_uuid)?.user_uuid ||
        "";
      const officeConfig = { ...data, default_account: default_user_uuid };
      dispatch(addNewOffice(officeConfig));
    }
  };

  const handleSuccessConfig = (data) => {
    // callbackData(data);
    addNewOfficeConfig(data);
    setTimeout(() => {
      callbackClose(false);
    }, 2000);
  };

  const handleSuccessConfigClassicReg = (data) => {
    // console.log("handleSuccessConfigClassicReg", data);
    dispatch(setRegData(data));
    callbackClose(false);
  };

  const handleSuccessConfigClassic = (data) => {
    dispatch(setRegData(data));
    addNewOfficeConfig(data);
  };

  const typesContent = {
    reg: {
      default: <TextBlock />,
      success: <RegForm callbackClose={callbackClose} />,
      callback: handleRegCallback,
      callbackSuccess: null,
      fields: default_reg_fields
    },
    sign_in: {
      default: <SignInText {...signInTextData} />,
      success: <SuccessQR />,
      callback: handleSuccessSignIn,
      callbackSuccess: null,
      fields: default_sign_in_config
    },
    office: {
      default: <ConfigText />,
      success: <SuccessQR />,
      callback: handleSuccessConfig,
      callbackSuccess: null,
      fields: default_office_config
    },
    office_classic_reg: {
      default: <ConfigText />,
      success: <SuccessQR />,
      callback: handleSuccessConfigClassicReg,
      callbackSuccess: null,
      fields: default_office_config
    }

    // classic_reg: {
    //   default: <ConfigText />,
    //   success: <ClassicRegForm type={CLASSIC_SIGN_UP} callbackClose={callbackClose} />,
    //   callback: handleSuccessConfigClassic,
    //   callbackSuccess: null,
    //   fields: default_office_config
    // },
    // classic_sign_in: {
    //   default: <ConfigText />,
    //   success: <ClassicRegForm type={CLASSIC_SIGN_IN} callbackClose={callbackClose} />,
    //   callback: handleSuccessConfigClassic,
    //   callbackSuccess: null,
    //   fields: default_office_config
    // }
  };

  const currentDefaultContent = typesContent[type].default;
  const currentSuccessContent = typesContent[type].success;
  const currentCallback = typesContent[type].callback;
  const currentCallbackSuccess = typesContent[type].callbackSuccess;
  const currentConfigFields = typesContent[type].fields;

  const handleStartScan = () => {
    setIsScanCompleted(false);
    setIsScanSuccess(false);
    setQrMessageType("default");
    setReqError(null);
    // setIsWrongQr(false);
    // setIsSuccessQr(false);
    setResetCam((prev) => !prev);
  };

  const handleIsScan = useCallback(async (data) => {
    try {
      if (!isScanSuccess) {
        let str = data.replace(/'/g, '"').replace("False", `\"false\"`);

        const obj = JSON.parse(str);
        // console.log("obj", obj);
        const objKeys = Object.keys(obj);
        const isValidQr = !currentConfigFields.some((field) => {
          const check = objKeys.indexOf(field) === -1;
          return check;
        });
        if (isValidQr) {
          if (!!currentCallbackSuccess) {
            currentCallbackSuccess();
          }
          await currentCallback(obj);
        } else {
          setQrMessageType("wrong_qr");
          // setIsWrongQr(true);
        }
        setIsScanSuccess(true);
      }
    } catch (e) {
      console.log("error isscan", e);
    } finally {
      setIsScanCompleted(true);
    }
  }, []);

  // useEffect(
  //   () => () => {
  //     dispatch(setRegData(null));
  //   },
  //   []
  // );

  return (
    <>
      <div style={{ padding: "16px 16px", height: "100vh", overflow: "auto" }}>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <Typography variant="subtitle1">Scan the QR code</Typography>
          <CloseButton handleCallback={callbackClose} />
        </div>
        <Divider sx={{ margin: "0 0 16px 0" }} />
        <div style={{ position: "relative" }}>
          <ScannerFocus
            isLoading={authLoading || officeLoading}
            isScan={isScanCompleted}
            handleStartScan={handleStartScan}
          />
          <ZBarScanner handleIsScan={handleIsScan} resetCam={resetCam} />
        </div>
        {qrMessageType === "default" && currentDefaultContent}
        {qrMessageType === "wrong_qr" && <WrongQR />}
        {qrMessageType === "success" && currentSuccessContent}
        {qrMessageType === "error" && <ErrorQR error={reqError} />}
      </div>
    </>
  );
};
