import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import thunk from "redux-thunk";
import authReducer from "./auth";
import officeReducer from "./office";
import notifyReducer from "./notify";
import scanerReducer from "./scaner";
import backHandlerReducer from "./backHandler";
import switcherReducer from "./switcher";
import settingsReducer from "./settings";

const reducers = combineReducers({
  auth: authReducer,
  office: officeReducer,
  notify: notifyReducer,
  switcher: switcherReducer,
  scaner: scanerReducer,
  settings: settingsReducer,
  backHandler: backHandlerReducer
});

export const store = configureStore({
  reducer: reducers,
  middleware: [thunk],
  devTools: true
});
