import { store } from "../redux/store";
import { setPasswordErrorData } from "../redux/notify";

export const handlePasswordError = (values) => {
  try {
    const dispatch = store.dispatch;
    // const current_user = store.getState().auth.selected_user_info;
    const url = values?.config?.url;
    const payload = JSON.parse(values?.config?.data);
    // console.log("url", url);
    // console.log("payload", payload);
    const data = {
      url: url,
      req_body: payload
    };
    dispatch(setPasswordErrorData(data));
  } catch (e) {}
};
