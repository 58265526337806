import { useFormContext } from "react-hook-form";

import { TextField, ListItem, Typography, IconButton } from "@mui/material";

import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import SaveIcon from "@mui/icons-material/Save";
import LaunchIcon from "@mui/icons-material/Launch";

export const FormTextFieldUrl = ({
  fieldLabel,
  fieldName,
  isDisabled,
  isAnyOffices,
  handleOpenScaner,
  handleShowOfficesModal
}) => {
  const {
    register,
    getValues,
    formState: { errors }
  } = useFormContext();

  const handleClickError = () => {
    // console.log("handleClickError");
    if (errors?.[`${fieldName}`]?.message.includes("url")) {
      window.open(getValues(fieldName));
    }
  };

  return (
    <>
      <Typography variant="subtitle2">{fieldLabel}</Typography>
      <ListItem key={`${fieldName}_backup`} disablePadding sx={{ marginBottom: "16px", position: "relative" }}>
        <TextField
          multiline={true}
          minRows={1}
          maxRows={6}
          autoFocus={true}
          type="url"
          disabled={isDisabled}
          name={`${fieldName}`}
          {...register(`${fieldName}`)}
          required
          fullWidth
          InputLabelProps={{ shrink: true }}
          InputProps={{
            endAdornment: (
              <>
                {isAnyOffices && (
                  <IconButton onClick={handleShowOfficesModal}>
                    {<FormatListBulletedIcon color={"primary"} />}
                  </IconButton>
                )}

                <IconButton onClick={handleOpenScaner}>{<QrCodeScannerIcon color="primary" />}</IconButton>
              </>
            )
          }}
          error={errors?.[`${fieldName}`]}
          sx={{
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderRadius: `12px`
              }
            }
          }}
          variant="standard"
        />
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            position: "absolute",
            bottom: "-32px",
            left: "0",
            fontSize: "14px"
          }}
        >
          <div style={{ display: "flex", alignItems: "center", color: "#d32f2f" }} onClick={handleClickError}>
            {errors?.[`${fieldName}`]?.message}{" "}
            {errors?.[`${fieldName}`]?.message.includes("url") && <LaunchIcon sx={{ marginLeft: "4px" }} />}
          </div>
        </div>
      </ListItem>
    </>
  );
};
