import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { setShowAllOffices } from "../../redux/switcher";
import { setSelectedOfficeInfo, setCurrentOfficeByUUID } from "../../redux/office";

import { styled } from "@mui/material/styles";
import { Card, CardContent, CardMedia, CardActionArea, ListItemAvatar } from "@mui/material";
import { ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";
import { Collapse, Divider, Chip, Button, IconButton, Alert, Paper } from "@mui/material";
import { TextField, MenuItem } from "@mui/material";
import { ExpandTitle } from "../../components/ExpandTitle/ExpandTitle";
import { OfficeForm } from "./OfficeForm";
import { OfficeAccountSelect } from "../../components/OfficeAccountSelect/OfficeAccountSelect";
import { OfficeTitleForm } from "../../components/OfficeTitle/OfficeTitleForm";
import { OfficeDescriptionForm } from "../../components/OfficeDescription/OfficeDescriptionForm";
import ApartmentIcon from "@mui/icons-material/Apartment";
import PublicIcon from "@mui/icons-material/Public";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import StarIcon from "@mui/icons-material/Star";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import BookmarkIcon from "@mui/icons-material/Bookmark";

import "./index.css";
import { openNotify } from "../../components/OpenNotify/openNotify";
import { appRoutes } from "../../utils/constants";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest
  })
}));

export const OfficeCard = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { all_users, current_user } = useSelector((state) => state.auth);
  const { all_offices, current_office } = useSelector((state) => state.office);

  const {
    address,
    description,
    description_comment,
    name,
    name_comment,
    pins,
    handleOpenConfirmDelete,
    service_uuid,
    biom_uuid,
    default_account,
    biom_name
  } = props;

  const [expanded, setExpanded] = useState(false);

  const handleShowMore = () => {
    // console.log("handleCardClick");

    setExpanded((prev) => !prev);
  };

  const handleCardClick = () => {
    // dispatch(setShowAllOffices(false));
    navigate(appRoutes.doors.path);
    dispatch(setSelectedOfficeInfo(service_uuid));
  };

  const handleMakeActive = () => {
    if (checkIsActiveOffice) {
      dispatch(setCurrentOfficeByUUID(null));
    } else {
      dispatch(setCurrentOfficeByUUID(service_uuid));
      openNotify("success", `Relay "${name_comment || name}" is selected as active`);
    }
  };

  const handleDeleteOffice = () => {
    handleOpenConfirmDelete(service_uuid);
  };

  const availableUsers = all_users.filter(
    (user) => user?.biom?.biom_uuid === biom_uuid || user?.biom?.uuid === biom_uuid
  );

  const checkIsActiveOffice = current_office?.service_uuid === service_uuid && !!default_account;

  return (
    <Card
      elevation={0}
      sx={{
        // width: "calc(100vw - 36px)",
        marginBottom: "8px",
        position: "relative",
        border: `1px solid ${checkIsActiveOffice ? "#1976d2" : "gray"} `,
        borderRadius: "16px",
        "& .MuiCardContent-root": { padding: "0 48px 0 0" },
        "& .MuiCardContent-root:last-child": { paddingBottom: "0" }
      }}
    >
      {/* <CardActionArea onClick={handleCardClick}>
      </CardActionArea> */}
      <CardContent>
        {!availableUsers?.length && (
          <Alert sx={{ paddingLeft: "8px" }} severity="warning">
            No accounts available
          </Alert>
        )}

        <ListItem sx={{ padding: "0 8px" }}>
          <ListItemIcon sx={{ minWidth: "36px" }}>
            <ApartmentIcon color={checkIsActiveOffice ? "primary" : "neutral"} />
          </ListItemIcon>
          <ListItemText>
            <Typography
              variant="subtitle1"
              sx={{ position: "relative", display: "inline-block" }}
              color={checkIsActiveOffice ? "primary" : "gray"}
            >
              {name_comment || name}
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ marginTop: "-8px" }}>
              {description_comment || description}
            </Typography>
          </ListItemText>
        </ListItem>
        <ListItem sx={{ padding: "4px 8px" }}>
          <ListItemIcon sx={{ minWidth: "36px" }}>
            <PublicIcon color={checkIsActiveOffice ? "primary" : "gray"} />
          </ListItemIcon>
          <ListItemText>
            <Typography variant="body2" color="text.secondary">
              {biom_name || "-"}
            </Typography>
          </ListItemText>
        </ListItem>
        <ListItem sx={{ padding: "4px 8px" }}>
          <ListItemIcon sx={{ minWidth: "36px", fontWeight: "600", color: "gray" }} color="primary">
            <span style={{ color: checkIsActiveOffice ? "#1976d2" : "gray" }}>IP</span>
          </ListItemIcon>
          <ListItemText>
            {Array.isArray(address)
              ? address.map((item) => (
                  <Typography variant="body2" color="text.secondary" key={item}>
                    {item}
                  </Typography>
                ))
              : ""}
          </ListItemText>
        </ListItem>
      </CardContent>

      <div className="office_card_btn_wrapper" style={{ display: !!default_account ? "block" : "none" }}>
        <Button
          variant={checkIsActiveOffice ? "contained" : "outlined"}
          size="round"
          onClick={handleMakeActive}
          color={"success"}
        >
          <BookmarkIcon />
        </Button>
      </div>

      <div style={{ paddingRight: "36px" }}>
        <ExpandTitle label={"More"} labelHide={"Hide"} isOpen={expanded} handleShowMore={handleShowMore} />
      </div>

      <div className="office_card_switch">
        <Button
          variant="contained"
          fullWidth
          sx={{ height: "100%", borderRadius: "0 16px 16px 0", padding: "0 0" }}
          onClick={handleCardClick}
          disabled={!availableUsers?.length}
        >
          <div style={{ display: "flex" }}>
            <MeetingRoomIcon sx={{ marginRight: "-8px" }} />
            <ChevronRightIcon />
          </div>
        </Button>
      </div>

      <Collapse in={expanded} timeout="auto" sx={{ padding: expanded ? "0 0 16px 0" : "0 0", transition: ".3s" }}>
        <CardContent>
          <OfficeAccountSelect
            availableUsers={availableUsers}
            service_uuid={service_uuid}
            default_account={default_account}
          />

          <OfficeTitleForm title={name_comment || name} service_uuid={service_uuid} />
          <OfficeDescriptionForm description={description_comment || description} service_uuid={service_uuid} />

          {/* <div style={{ margin: "32px 0" }}>
            <Divider sx={{ background: "gray" }} />
          </div> */}
          <ListItem sx={{ marginTop: "32px", padding: "0 8px" }}>
            <Button fullWidth variant="outlined" color="error" onClick={handleDeleteOffice}>
              <DeleteForeverIcon />
              Delete an office
            </Button>
          </ListItem>
        </CardContent>
      </Collapse>
    </Card>
  );
};
