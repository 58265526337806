import { Typography, Card, CardActionArea, CardContent } from "@mui/material";
import { scannerType } from "../../utils/constants";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";

export const NoOffices = ({ handleScanConfig }) => {
  return (
    <CardActionArea sx={{ marginBottom: "16px" }} onClick={() => handleScanConfig(scannerType.office)}>
      <Card elevation={4} sx={{ height: "120px", display: "flex", justifyContent: "center", alignItems: "center" }}>
        <CardContent sx={{ textAlign: "center" }}>
          <QrCodeScannerIcon sx={{ marginBottom: "8px", fontSize: "48px" }} color="primary" />
          <Typography variant="subtitle1" sx={{ textAlign: "center" }}>
            Scan the QR code of the Relay
          </Typography>
        </CardContent>
      </Card>
    </CardActionArea>
    // <div>
    //   <Typography variant="subtitle1" sx={{ textAlign: "center" }}>
    //     No relays available
    //   </Typography>
    //   <Typography sx={{ textAlign: "center" }} variant="subtitle2">
    //     There is not a single relay configuration.
    //   </Typography>
    //   <Button
    //     fullWidth
    //     variant="contained"
    //     sx={{ marginTop: "16px" }}
    //     onClick={() => handleScanConfig(scannerType.office)}
    //   >
    //     {" "}
    //   </Button>
    // </div>
  );
};
