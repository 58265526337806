import { useSelector, useDispatch } from "react-redux";
import { deleteOffice, setDefaultAccount } from "../../redux/office";
import { useState, useEffect } from "react";
import { OfficeCard } from "./OfficeCard";
import { PageWrapper } from "../../components/PageWrapper/PageWrapper";
import { NoOffices } from "./NoOffices";
import FullScreenDialog from "../../components/FullScreenDialog/FullScreenDialog";
import { ScannerComponent } from "../../components/Scaner/ScannerComponent";
import { scannerType } from "../../utils/constants";
import { ConfirmModal } from "../../components/ConfirmModal/ConfirmModal";

import { Button, Typography } from "@mui/material";
import CropFreeIcon from "@mui/icons-material/CropFree";
import RoomPreferencesIcon from "@mui/icons-material/RoomPreferences";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";

export const Offices = () => {
  const dispatch = useDispatch();
  const { all_users } = useSelector((state) => state.auth);
  const { all_offices } = useSelector((state) => state.office);

  const [openDialog, setOpenDialog] = useState(false);

  const [openConfirm, setOpenConfirm] = useState(false);
  const [officeUuidToDelete, setOfficeUuidToDelete] = useState(null);

  const [currentScannerType, setCurrentScannerType] = useState(scannerType.office);

  const scanConfig = <ScannerComponent type={currentScannerType} callbackClose={setOpenDialog} />;

  const handleOpenDialog = (scannerType) => {
    setCurrentScannerType(scannerType);
    setOpenDialog(true);
  };

  const handleOpenConfirmDelete = (service_uuid) => {
    setOpenConfirm(true);
    setOfficeUuidToDelete(service_uuid);
  };

  const handleDeleteConfig = (bool) => {
    if (bool) {
      dispatch(deleteOffice(officeUuidToDelete));
      setOfficeUuidToDelete(null);
    }
  };

  const text = "The configuration will be deleted. Are you sure?";

  const pageContent = (
    <>
      {!!all_offices?.length && (
        <div className={"fixed_switch_btn"}>
          <Button
            variant="contained"
            startIcon={<CropFreeIcon />}
            onClick={() => handleOpenDialog(scannerType.office)}
            sx={{ padding: "8px 16px", borderRadius: "16px 16px 16px 6px" }}
          >
            Add a relay
          </Button>
        </div>
      )}

      <div style={{ marginTop: "56px" }}></div>
      {!!all_offices?.length ? (
        all_offices.map((config) => {
          if (!config?.default_account) {
            const availableUsers = all_users.filter(
              (user) => (user?.biom?.biom_uuid || user?.biom?.uuid) === config?.biom_uuid
            );
            if (!!availableUsers?.length) {
              const data = { user_uuid: availableUsers[0]?.user_uuid, service_uuid: config?.service_uuid };
              dispatch(setDefaultAccount(data));
            }
          }

          return (
            <OfficeCard {...config} handleOpenConfirmDelete={handleOpenConfirmDelete} key={config?.service_uuid} />
          );
        })
      ) : (
        <>
          <NoOffices handleScanConfig={handleOpenDialog} />
        </>
      )}

      <FullScreenDialog children={scanConfig} open={openDialog} setOpen={setOpenDialog} />
      <ConfirmModal
        open={openConfirm}
        setOpen={setOpenConfirm}
        callback={handleDeleteConfig}
        text={text}
        isDelete={true}
      />
      <div></div>
    </>
  );

  return pageContent;
};
