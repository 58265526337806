import { store } from "../redux/store";
import { getEcosystemSession } from "./getEcosystemSession";
import { getClassicSession } from "./getClassicSession";

import { updateAccountInfo } from "../redux/auth";

import { checkToken } from "./checkToken";
import { saveToken } from "./saveToken";
import { checkFastestUrl } from "./checkFastestUrl";
import { setIsTrustedUrlError } from "../redux/notify";

export const handleToken = async (account, service, isReset, newMd5Password) => {
  // console.log("handleToken", account, service, isReset, newMd5Password);
  try {
    const dispatch = store.dispatch;
    const actorType = account?.actor_type;
    const service_uuid = service?.service_uuid;
    const user_uuid = account?.user_uuid;

    let token = checkToken(user_uuid, service_uuid);
    if (!!token && !isReset) {
      return token;
    }
    const url = await checkFastestUrl(service?.address[0], service?.address[1]);
    if (!url) {
      dispatch(setIsTrustedUrlError([service?.address[0], service?.address[1]]));
      return null;
    }

    const email = account?.email;
    const password = newMd5Password || account?.password;
    const user_priv_key = account?.user_priv_key;

    let tokenData;
    if (actorType === "classic_user") {
      tokenData = await getClassicSession(url, email, password);
    } else {
      tokenData = await getEcosystemSession(url, user_uuid, user_priv_key);
    }

    const session_token = tokenData?.session_token;
    const expiration = tokenData?.expiration;

    if (!!newMd5Password) {
      const newAccountData = { ...account, password: newMd5Password };
      dispatch(updateAccountInfo(newAccountData));
    }
    if (!!session_token) {
      saveToken(session_token, expiration, user_uuid, service_uuid);
    }

    // console.log("handleNewToken", session_token);
    return session_token;
  } catch (e) {
    console.log("handleNewToken", JSON.stringify(e));
  }
};
