import { useDispatch, useSelector } from "react-redux";
import { useState, useRef, useEffect } from "react";

import { Card, TextField, ListItem, Collapse, ListItemText } from "@mui/material";
import { Button, Typography, Alert, IconButton } from "@mui/material";
import { CloseButton } from "../CloseButton/CloseButton";
import { FormTextField } from "../FormTextField/FormTextField";

import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import SaveIcon from "@mui/icons-material/Save";
import LockIcon from "@mui/icons-material/Lock";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import { openNotify } from "../OpenNotify/openNotify";

import { encryptBackup } from "../../utils/encryptBackup";

export const BackupCreateForm = ({ callbackClose, uuid, first_name, last_name }) => {
  const { all_users } = useSelector((state) => state.auth);
  const { all_offices } = useSelector((state) => state.office);
  const { all_services } = useSelector((state) => state.settings);
  const fileRef = useRef(null);
  const [showPass, setShowPass] = useState(false);
  const [showPassRepeat, setShowPassRepeat] = useState(false);

  const [successBackup, setSuccessBackup] = useState(false);

  const defaultValues = {
    name: "",
    password: "",
    repeat_password: ""
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Required field"),
    password: Yup.string()
      .test("is_min", "At least 4 characters", (val, values) => val?.length >= 4)
      .required("Required field"),
    repeat_password: Yup.string()

      .test("is_pass_equal", "Passwords don't match", (val, values) => val === values?.parent?.password)
      .required("Required field")
  });

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    reValidateMode: "onChange",
    mode: "onSubmit",
    shouldFocusError: true,
    defaultValues: {
      ...defaultValues
    }
  });

  const {
    watch,
    register,
    reset,
    setValue,
    handleSubmit,
    formState: { errors, isSubmitting, isSubmitted, isSubmitSuccessful }
  } = methods;

  useEffect(() => {
    reset({ ...defaultValues, name: `${first_name}_${last_name}` });
  }, [first_name, last_name]);

  const fieldLabel = (fieldName) => {
    switch (fieldName) {
      case "name":
        return "Backup name";
      case "password":
        return "Password";
      case "repeat_password":
        return "Repeat password";
    }
  };

  const onSubmit = async (values) => {
    try {
      await downloadFile(values?.password, values?.name);
      setSuccessBackup(true);
      setTimeout(() => {
        callbackClose(false);
        setSuccessBackup(false);
      }, 1500);
    } catch (e) {
      console.log("e", e);
      setSuccessBackup(false);
      openNotify("error", "Error when creating a backup");
    }
  };

  const downloadFile = async (pass, fileName) => {
    const accountToBackup = all_users.slice(0).find((account) => account?.user_uuid === uuid);
    const officesToBackup = all_offices
      .slice(0)
      .filter((office) => office?.biom_uuid === accountToBackup?.biom?.biom_uuid);
    const data = {
      account: accountToBackup,
      offices: !!officesToBackup?.length ? officesToBackup : [],
      services: !!all_services?.length ? all_services : []
    };
    // console.log("data", data);
    const dataToEncrypt = JSON.stringify(data);
    const encryptedContent = await encryptBackup(dataToEncrypt, pass);
    const file = new Blob([encryptedContent], { type: "text/plain" });
    fileRef.current.href = URL.createObjectURL(file);
    fileRef.current.download = `${fileName}.esx54.txt`;
    fileRef.current.click();
  };

  return (
    <div style={{ padding: "16px 16px", height: "100vh", overflow: "auto" }}>
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "0 0 16px 0" }}>
        <Typography variant="subtitle1">Create a backup</Typography>
        <CloseButton handleCallback={callbackClose} />
      </div>

      <Card elevation={0} sx={{ border: "1px solid rgba(0, 0, 0, 0.12)", borderRadius: "16px" }}>
        <Alert severity="info" sx={{ alignItems: "center" }} icon={<SaveIcon />}>
          Your account will be saved locally on your device. The backup can be transferred to your other device.
        </Alert>
        <FormProvider {...methods}>
          {Object.keys(defaultValues).map((item) => {
            const isPassword = item.includes("password");
            const maxLength = isPassword ? 16 : 28;
            const isVisible = !(
              !!(item === "password" && !showPass && "password") ||
              !!(item === "repeat_password" && !showPassRepeat && "password") ||
              false
            );
            const callbackVisibility =
              (item === "password" && setShowPass) || (item === "repeat_password" && setShowPassRepeat) || null;
            return (
              <>
                <div style={{ padding: "16px 16px 32px 16px" }}>
                  <FormTextField
                    fieldLabel={fieldLabel(item)}
                    fieldName={item}
                    maxLength={maxLength}
                    isVisible={isVisible}
                    callbackVisibility={callbackVisibility}
                    isDisabled={successBackup}
                  />
                </div>

                {item === "name" && (
                  <Alert severity="info" sx={{ alignItems: "center" }} icon={<LockIcon />}>
                    Enter the password for the backup. Password will protect your backup from unauthorized access.
                  </Alert>
                )}
              </>
            );
          })}
        </FormProvider>

        <ListItem>
          <a hidden ref={fileRef}></a>
          <Button fullWidth variant="outlined" onClick={handleSubmit(onSubmit)} disabled={successBackup}>
            Create a backup
          </Button>
        </ListItem>
        <Collapse in={successBackup} timeout="auto" sx={{ transition: ".3s" }}>
          <Alert severity="success">The backup was successfully created</Alert>
        </Collapse>
      </Card>
    </div>
  );
};
