import isOnline from "is-online";
import { store } from "../redux/store";
import { setIsConnection } from "../redux/notify";

export const checkConnection = async () => {
  const dispatch = store.dispatch;
  const check = await isOnline();
  dispatch(setIsConnection(check));
  // console.log("checkConnection", check);
  return check;
};
